import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { API } from '../../../api/API';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';

const TablePartenaire = ({type="cliniques"}) => {
    const [partenaires, setpartenaires] = useState([]) ;
    const navigate =useNavigate()
    

    useEffect(() => {
         //call getpartenaire fonction here and setpartenaire
         getpartenaires()
    }, []) 
    

    const getpartenaires = async () => {
      try {
          const res = await API.get(`/partenaires/${type}?limit=${20}`);
          const { data } = res.data;
          setpartenaires(data);
      } catch (error) {
          console.log(`Error fetching data: ${error}`);
      }
  }
    const HandleUpdate=(element)=>{
        try {
          navigate(`../${appRoutes.register_partenaire}`,{state:{partenaire:element}})
        } catch (error) {
          console.log(`error ${error}`)
        }
        
    }
    const HandleDelete=async (id)=>{
        try {       
          console.log(`id ${JSON.stringify(id) }`)
          await API.delete(`/partenaire/${id} `);
         getpartenaires() ;
         } catch (error) {
          console.log(`some error occure! ${error}  `)
        }
          
      }

    return (
        <div className='p-1 m-1 accordion-collapse ' >
            
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Intitulé</th>
                        <th>Code</th>
                        <th>Email</th>
                        <th>Téléphone</th>
                        <th>Adresse</th>
                        <th>Type</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {partenaires.map((partenaire, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{partenaire.intitule}</td>
                            <td>{partenaire.code}</td>
                            <td>{partenaire.email}</td>
                            <td>{partenaire.tel}</td>
                            <td>{partenaire.adresse}</td>
                            <td>{partenaire.type}</td>
                            <td>
                                <span onClick={() => HandleUpdate(partenaire)} className='btn btn-primary m-1 me-1'>
                                      <CiEdit size={35} /></span>
                               
                                <span onClick={() => HandleDelete(partenaire)} className='btn btn-danger m-1 me-1'>
                                              <MdOutlineDeleteOutline size={30} /></span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default TablePartenaire;
