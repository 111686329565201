import React, { useEffect } from 'react'
import SideBar from '../../admin/components/SideBar'
import TopBar from '../../admin/components/TopBar'
import { Spinner } from 'react-bootstrap'
import { Outlet, useNavigate } from 'react-router-dom'
import useUserStore from '../../store/store'
import SideBarAgent from '../components/SideBarAgent'

const HomeAgent = () => {
  const navigate=useNavigate()
  const { isAuth}= useUserStore()
   useEffect(() => {
      if (!isAuth) {
     //   navigate('../', { replace: true })
      }
   }, [isAuth])

  return (
    <div className='m-0 p-0 h-100'>
           <SideBarAgent />
           <TopBar/>
           <div className='content  ' style={{marginLeft:'250px',marginTop:'100px'}} >
             
                       <div className='d-flex justify-content-center '>
                           <Spinner animation="grow" variant="success" />
                       </div> 
                  
                       <Outlet/>
            </div>     
        </div>
  )
}

export default HomeAgent
