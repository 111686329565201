
import axios from 'axios';
import { appString } from '../constant/constant';
import useUserStore from '../store/store';

const host ="127.0.0.1";//"54.205.80.54";//
const baseUrl =`https://ancient-springs-10322-4497b0f6c866.herokuapp.com/api/v1`// `http://${host}:${5000}/api/v1` ;// ;


const admin={
    login:`${baseUrl}/admin_assurance/login` ,
    getById:(id)=> (`/users/${id} `),
}


const login=`${baseUrl}/asssurance/agent/login`;

const APIs ={ 
      admin,
      login
   }

  ;

  const API = axios.create({
    baseURL: baseUrl,
  });
  
  API.interceptors.request.use(
    (config) => {
      const authToken = localStorage.getItem(appString.laafiseebAuth);
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  
  API.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        const userStore = useUserStore.getState();
        userStore.logout(); // Réinitialise l'état utilisateur
        // Optionnel : Rediriger l'utilisateur vers la page de connexion
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );



export {API,baseUrl};
