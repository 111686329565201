import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { API } from '../../../api/API';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';

const TableMaladie = () => {
    const [maladies, setMaladies] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchMaladies();
    }, []);

    const fetchMaladies = async () => {
        try {
            const res = await API.get('/maladies');
            const data = res.data.data;
            setMaladies(data);
        } catch (error) {
            console.log(`Error fetching data: ${error}`);
        }
    }

    const handleUpdate = (maladie) => {
        navigate(`../${appRoutes.register_maladie}`, { state: maladie });
    }



    const handleDelete =async (id) => {
        try {       
            const resp=await API.delete(`/maladie/${id} `);
           fetchMaladies();
           } catch (error) {
            console.log(`some error occure! ${error}  `)
          }
    }

    return (
        <div>
            <p>Maladies</p>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Label</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {maladies.map((maladie, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{maladie.label}</td>
                            <td>{maladie.description}</td>
                            <td>
                                <span className='btn btn-primary me-1' onClick={() => handleUpdate(maladie)}><CiEdit size={35} /></span>
                                <span  className='btn btn-danger me-1' onClick={() => handleDelete(maladie.id)}><MdOutlineDeleteOutline size={30} /></span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default TableMaladie;
