import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { object, string, number, InferType } from 'yup';
import { Formik } from 'formik';

import Post from '../../utils/Post';
import {API }from '../../api/API';
import useUserStore from '../../store/store';

const Register = () => {

    const { updateUser,updateToken } = useUserStore(); 
    const userSchema = object({
        nom: string().required(),
        prenom: string().required(),
        email: string().email().required(),
        tel: string(),
        address: string(),
        password: string().required().min(6),
        conf_password: string().required(),
        photo: null,
        
    });

    const save = async (url, userData) => {
        try {
          const response = await Post(url,userData);
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Erreur de serveur');
          }
        } catch (error) {
          throw error;
        }
      };

    
    return (
        <div className='login-page w-100 container-fluid h-100 d-flex align-items-center justify-content-center'>
            <div className='opacity-75 bg-light rounded-4 p-4'>
                <Formik
                    validationSchema={userSchema}
                    onSubmit={ async(values, { setSubmitting }) => {
                            const user =await save(API.register,values);
                            updateUser(user.user);
                            updateToken(user.token);
                            console.log(`values from submit  ${JSON.stringify(values) } `)
                            setSubmitting(false);
                    }}
                    validate={(values)=>{
                        console.log(`values from valide ${JSON.stringify(values)} `)
                        const errors ={}
                          if (values.password!==values.conf_password) {
                           // errors.conf_password="Mot de passe different"
                          }
                        return errors

                    } }
                    initialValues={{ nom: '', prenom: '', tel: '', address: '', email: '', password: '', conf_password: '', photo: null }}
                     >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValidating }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control
                                        name="nom"
                                        required
                                        type="text"
                                        value={values.nom}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Votre nom"
                                        isInvalid={!!errors.password}
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Control
                                        name="prenom"
                                        required
                                        type="text"
                                        isInvalid={!!errors.password}
                                        value={values.prenom}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Prénom"
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col}>
                                    <Form.Label>Email</Form.Label>
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                        <Form.Control
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            aria-describedby="inputGroupPrepend"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={!!errors.password}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">Entrez un email valide</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Tel: </Form.Label>
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend">+226</InputGroup.Text>
                                        <Form.Control
                                            name="tel"
                                            type="text"
                                            placeholder="Optional:Numéro de téléphone"
                                            aria-describedby="inputGroupPrepend"
                                            value={values.tel}
                                            onChange={handleChange}
                                            isInvalid={!!errors.password}
                                            onBlur={handleBlur}
                                        />
                                        <Form.Control.Feedback type="invalid">Entrez un numéro valide</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Mot de passe:</Form.Label>
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        placeholder="Choisissez un mot de passe"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={!!errors.password}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Entrez un mot de passe valide</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Confirmez votre mot de passe:</Form.Label>
                                    <Form.Control
                                        name="conf_password"
                                        type="password"
                                        placeholder="Confirmez votre mot de passe"
                                        value={values.conf_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={!!errors.password}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Entrez un mot de passe valide</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="position-relative mb-3">
                                    <Form.Label>Image de profil: </Form.Label>
                                    <Form.Control
                                        name="photo"
                                        type="file"
                                        onChange={handleChange}
                                        isInvalid={!!errors.photo}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.photo}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            {console.log(`isvalidatin ${JSON.stringify( errors)} `)}
                            <Button type="submit" className='bg-danger' disabled={isSubmitting}>Enregistrer</Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default Register;
