import React from 'react'
import TablePartenaire from './Tables/TablePartenaire'
import { Link } from 'react-router-dom'
import { appRoutes } from '../../constant/constant'

const Pharmacy = () => {
    return (
        <div className='p-2 ' >
            <div className='m-2 ' >
               <Link to={`../${appRoutes.register_partenaire}`} state={{type:'pharmacy'}} className='btn bg-success ' >Ajouter</Link>
            </div>
            <TablePartenaire type='pharmacies' />
        </div>
    )
}

export default Pharmacy