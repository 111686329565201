import React from 'react'
import { useLocation } from 'react-router-dom'

const ProfilAgent = () => {
 const locatiion = useLocation() ;


 console.log("location" ,locatiion)
    return (
        <div>
            
        </div>
    )
}

export default ProfilAgent