import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { GrFormView } from "react-icons/gr";
import { API } from '../../../api/API';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';

const TableOffre = () => {
    const [offres, setOffres] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchOffres();
    }, []);

    const fetchOffres = async () => {
        try {
            const res = await API.get('/offres');
            const data = res.data;
            setOffres(data);
        } catch (error) {
            console.log(`Error fetching data: ${error}`);
        }
    }

    const handleUpdate = (offre) => {
        navigate(appRoutes.register_offre, { state: offre });
    }

    const handleView = (offre) => {
        // Handle view logic
    }

    const handleDelete = (offre) => {
        // Handle delete logic
    }

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Intitulé</th>
                        <th>Description</th>
                        <th>Coût</th>
                        <th>Durée de validité</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {offres.map((offre, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{offre.code}</td>
                            <td>{offre.intitule}</td>
                            <td>{offre.description}</td>
                            <td>{offre.cout}</td>
                            <td>{offre.dureeValidite}</td>
                            <td>
                                <span onClick={() => handleUpdate(offre)}><CiEdit size={35} /></span>
                                <span onClick={() => handleView(offre)}><GrFormView size={35} /></span>
                                <span onClick={() => handleDelete(offre)}><MdOutlineDeleteOutline size={30} /></span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default TableOffre;
