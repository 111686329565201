import React, { useEffect } from 'react'

import TopBar from '../../admin/components/TopBar'
import { Spinner } from 'react-bootstrap'
import { Outlet, useNavigate } from 'react-router-dom'
import useUserStore from '../../store/store'

import SideBarMedecin from '../components/SideBarMedecin'

const HomeMedecin  = () => {
  const navigate=useNavigate()
 
  

  return (
    <div className='m-0 p-0 h-100'>
           <SideBarMedecin/>
           <TopBar/>
           <div className='content'  >                     
                  
                       <Outlet/>
            </div>     
        </div>
  )
}

export default HomeMedecin ;
