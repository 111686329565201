import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link, useNavigate } from 'react-router-dom';

import {API, baseUrl} from '../../api/API';
import useUserStore from '../../store/store';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { appRoutes, appString } from '../../constant/constant';

// Import des autres dépendances...

const Login = () => {
  const { updateAll } =useUserStore() ;
 const navigate= useNavigate() ;
 const [loginErro, setloginErro] = useState(null)

  const userSchema = object({
      email: string().email().required(),
      password: string().required().min(6),
  });

  return (
      <div className='login-page w-100 container-fluid h-100 d-flex'>
          <div className='opacity-75 container bg-light align-self-center d-flex flex-column align-items-center rounded-4 p-4 pt-2' style={{ maxWidth: '500px' }}>
              <h2 className='h2'> Connexion</h2>
              <Formik
                  validationSchema={userSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                               try {
                                      // Faire un appel à l'API ici...
                                const resp =await fetch(`${baseUrl}/asssurance/agent/login`,{
                                 method:'POST',
                                 headers:{
                                  "Content-Type": "application/json",
                                   },
                                  body:JSON.stringify(values) 
                                   });
                                 const data =await resp.json();                     
                                  //   console.log("values",JSON.stringify(data) );
                                  if (data.user) {
                                    updateAll(data.user,data.token,true);
                                    /**mettre le token en localsorage pou utiliser dans la requete */
                                    localStorage.setItem(appString.laafiseebAuth,data.token);
                                     setTimeout(()=>{navigate('../') },1000)
                                  }else{
                                       setloginErro(` ${data.message}`)
                                  }
                               
                                } catch (error) {
                                   console.log(`an error eccur ${JSON.stringify(error)}`)
                             }
                  
                  }}
                  initialValues={{ email: '', password: '' }}
                 >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValidating }) => (
                      <Form noValidate className='p-2' onSubmit={handleSubmit}>
                          <Form.Group md='4' controlId='validationCustomUsername'>
                              <Form.Label>Email</Form.Label>
                              <InputGroup hasValidation>
                                  <InputGroup.Text id='inputGroupPrepend'>@</InputGroup.Text>
                                  <Form.Control
                                      type='email'
                                      placeholder='Votre adresse email'
                                      name='email' // Assurez-vous d'ajouter le nom du champ ici
                                      value={values.email} // Liaison de la valeur du champ à l'état local
                                      onChange={handleChange} // Gestion de l'événement onChange
                                      onBlur={handleBlur} // Gestion de l'événement onBlur
                                      isInvalid={touched.email && !!errors.email} // Utilisation des erreurs de validation de Formik
                                      required
                                  />
                                  <Form.Control.Feedback type='invalid'>
                                      {errors.email}
                                  </Form.Control.Feedback>
                              </InputGroup>
                          </Form.Group>
                          <Form.Group md='4' controlId='validationCustomPassword'>
                              <Form.Label>Mot de passe</Form.Label>
                              <Form.Control
                                  type='password'
                                  placeholder='Votre mot de passe'
                                  name='password' // Assurez-vous d'ajouter le nom du champ ici
                                  value={values.password} // Liaison de la valeur du champ à l'état local
                                  onChange={handleChange} // Gestion de l'événement onChange
                                  onBlur={handleBlur} // Gestion de l'événement onBlur
                                  isInvalid={touched.password && !!errors.password} // Utilisation des erreurs de validation de Formik
                                  required
                              />
                              <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
                          </Form.Group>
                          {loginErro?<p className='text-danger h6' >{ loginErro} </p>:null}
                          <Button type='submit' className='w-100 bg-success my-2' disabled={isSubmitting}>
                              Valider
                          </Button>
                      </Form>
                  )}
              </Formik>
              <Link to={`${appRoutes.changePassWord}`} className=' text-danger pt-0 mt-0'><small>Mot de passe oublié ?</small> </Link>
          </div>
      </div>
  );
};

export default Login;


