import React from 'react';
import { Card, Col, Container, Row, Image } from 'react-bootstrap';
import useUserStore from '../store/store';
import { FaEnvelope, FaPhone, FaUser } from 'react-icons/fa';

const CurrentUserProfil = () => {
    const { user } = useUserStore();

    return (
        <div className="mt-5 bg-secondary">
            <Row className="justify-content-center m-1">
                <Col md={8}>
                    <Card className="shadow-sm p-3 mb-5 mt-4 bg-white rounded">
                        
                        <Card.Body>
                            <Row className="align-items-center">
                                <Col md={4} className="text-center">
                                    <Image 
                                        src={user.image || 'https://via.placeholder.com/150'} 
                                        roundedCircle 
                                        fluid 
                                        alt="User Profile"
                                        style={{ width: '150px', height: '150px' }}
                                    />
                                </Col>
                                <Col md={8}>
                                    <h4><FaUser className="me-2" />{user.fullName}</h4>
                                    <p><FaEnvelope className="me-2" />{user.email}</p>
                                    <p><FaPhone className="me-2" />{user.tel}</p>
                                    <p><strong>Type:</strong> {user.type}</p>
                                  
                                   
                                    <p><strong>Créé le:</strong> {new Date(user.createdAt).toLocaleDateString()}</p>
                                    <p><strong>Dernière mise à jour:</strong> {new Date(user.updatedAt).toLocaleDateString()}</p>
                                    <p><strong>Mail Validé:</strong> {user.isMailValid ? 'Oui' : 'Non'}</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CurrentUserProfil;
