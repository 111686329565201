import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import formatDateTime from '../../../utils/dateFormate';
import { API } from '../../../api/API';
import TableTemplatePrescription from '../TableTemplates/TableTemplatePrescription';


const ProfilAssure = () => {
    const location = useLocation();
    const [assure, setAssure] = useState(null);
    const [police, setPolice] = useState(null);
    const [structure, setStructure] = useState(null);
    const [prescriptions , setPrescriptions]=useState(null) ;

    useEffect(() => {
        if (location.state ) {
            const { Polouse , Structure} = location.state;
            setAssure(location.state);
            setPolice(Polouse);
            setStructure(Structure); 
           
        }
        
        if (location.state && location.state.id) {
            const id =location.state.id ;
            getPrescription(id)
        }
       
    }, [location.state]);
  
   
    
    const getPrescription =(id)=>{
        if (id) {
            API.get(`/assure/prescriptions/${id}`).then((res)=>{
               setPrescriptions(res.data.data) ;
               
             })
        }
    }
    if (!assure) {
        return <p>Loading...</p>;
    }
   
    return (
        <div className='container-fluid m-2 profil-bg'>
            <h1 className='m-1 fs-1'>Profil de l'Assuré</h1>

            <Card className='mt-4 p-3'>
                <Row>
                    <Col md={4} className='border-end'>
                        <img src={assure.image?assure.image:require('../../../assets/user.png') } 
                              alt="image de l'assure" style={{width:'100px'}} />
                        <h3 className='border-bottom'>Assuré</h3>
                        <p className='fw-bold'>{`${assure.nom} ${assure.prenom}`}</p>
                        {assure.cni?<p><strong>CNI:</strong> {assure.cni}</p>:null}
                        <p><strong>rfid:</strong> {assure.rfid}</p>
                        <p><strong>Email:</strong> {assure.email}</p>
                        <p><strong>Responsable:</strong> {assure.matricule_resp}</p>
                        <p><strong>Adresse:</strong> {assure.adresse}</p>
                        <p><strong>Date de naissance:</strong> {formatDateTime(assure.date_naissance)}</p>
                        <p><strong>Matricule:</strong> {assure.matricule}</p>
                        <p><strong>Téléphone:</strong> {assure.tel}</p>
                    </Col>
                    {police?<Col md={4} className='border-end'>
                        <h3 className='border-bottom'>Police </h3>
                        <p className='fw-bold'>{police.libelle}</p>
                        <p><strong>Numéro:</strong> {police.numero}</p>
                        <p><strong>Description:</strong> {police.description}</p>
                        <p><strong>Taux de couverture:</strong> {police.taux_couvrement}%</p>
                        <p><strong>Coût d'abonnement:</strong> {police.cout_abonnement}</p>
                        <p><strong>Durée de validité:</strong> {police.dureeValidite} jours</p>
                    </Col>:null}
                    {structure?
                      <Col md={4}>
                            <h3 className='border-bottom'> Structure</h3>
                            <p className='fw-bold'>{structure.intitule}</p>
                            <p><strong>Email:</strong> {structure.email}</p>
                            <p><strong>Adresse:</strong> {structure.adresse}</p>
                            <p><strong>Téléphone:</strong> {structure.tel}</p>
                      </Col>:null}
                </Row>
            </Card>
           <Card className='mt-2 '>
              <Row >
                 {prescriptions? <TableTemplatePrescription prescriptions={prescriptions} />:null}
              </Row>
           </Card>

        </div>
    );
};

export default ProfilAssure;
