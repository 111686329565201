import React from 'react'

const MyAlert = ({message, variant="alert-secondary"}) => {

    return (
        <div >
            <div class={`alert ${variant}` }role="alert">
                 {message}
             </div>
        </div>
    )
}

export default MyAlert