import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { API } from '../../../api/API';
import { Card, Row, Col } from 'react-bootstrap';
import UserCard from '../UserCard';

const ProfilStructure = () => {
    const location = useLocation();
    const [structure, setStructure] = useState(null);
    const [employes, setEmployes] = useState([]);

    useEffect(() => {
        if (location.state && location.state.structure) {
            const { structure } = location.state;
            setStructure(structure);
            getDetail(structure.id);
        }
    }, [location.state]);

    const getDetail = (id) => {
        if (id) {
            API.get(`/structure/${id}/details/`).then((res) => {
                const { assures, ...structure } = res.data.data;
                setStructure(structure);
                setEmployes(assures);
            }).catch((error) => {
                console.error(`Error fetching details: ${error}`);
            });
        }
    };

    if (!structure) {
        return <p>Loading...</p>;
    }

    return (
        <div className='container-fluid m-2 p-2 profil-bg'>
            <Card className='mb-4'>
                <Card.Header>
                    <h3>{structure.intitule}</h3>
                </Card.Header>
                <Card.Body>
                    <p><strong>Adresse:</strong> {structure.adresse}</p>
                    <p><strong>Email:</strong> {structure.email}</p>
                    <p><strong>Téléphone:</strong> {structure.tel}</p>
                    <p><strong>Code:</strong> {structure.code}</p>
                </Card.Body>
            </Card>

            <h4>Liste des employés</h4>
            <Row>
            {employes.map((employe) => (
                    <Col md={4} key={employe.id}>
                        <UserCard employe={employe} />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default ProfilStructure;
