import React, { useEffect } from 'react'
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { GrFormView } from "react-icons/gr";
import {API } from '../../../api/API';
import { Link, useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';


const AssureTable = () => {
    const [assures, setAssures] = useState([]) ;
    const navigate =useNavigate()
    

    useEffect(() => {
         //call getAssure fonction here and setAssure
         getAssures()
    }, []) 
    

    const getAssures = async () => {
      try {
          const res = await API.get(`/assures`);
          const { data, total } = res.data;
          setAssures(data);
        
      } catch (error) {
          console.log(`Error fetching data: ${error}`);
      }
  }
    const HandleUpdate=(element)=>{
        try {
          navigate(`../${appRoutes.register_assure}`,{state:{assure:element}})
        } catch (error) {
          console.log(`error ${error}`)
        }
        
    }
    const HandleView=(element)=>{
      try {
        
        navigate(`../${appRoutes.profile_assure}`, { element  })
      } catch (error) {
        console.log(`error ${error}`)
      }
     
    }


    const HandleDelete=async (id)=>{
      try {       
        const resp=await API.delete(`/assure/${id} `);
        getAssures() ;
       } catch (error) {
        console.log(`some error occure! ${error}  `)
      }
        
    }


    return (
        <div>
        <Table striped bordered hover>
        <thead>
        <tr>
          <th>#</th>
          <th>nom</th>
          <th>prenom</th>
          <th>email</th>
          <th>telephone</th>
          <th>adresse</th>
          <th>matricule</th>
          <th>Rfid</th>
          <th>cni</th>
          <th> Police</th>
          <th>Structure</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {assures.map((assure,index)=>(
              <tr>
              <td>{`${index+1}`} </td>
              <td>{assure.nom }</td>
              <td>{assure.prenom }</td>
              <td>{assure.email }</td> 
              <td>{assure.tel }</td> 
              <td>{assure.adresse}</td> 
              <td>{assure.matricule }</td> 
              <td>{assure.rfid} </td>
              <td>{assure.cni }</td> 
              <td> 
                      <Link to={`../${appRoutes.profile_police}`} state={assure.Polouse} 
                            className='link-secondary link-underline-opacity-0 ' >
                            {assure.Polouse?assure.Polouse.libelle:null} 
                      </Link> 
                </td> 
               <td>   
                    <Link to={`../${appRoutes.strucuture_profile}`} state={assure.Structure} 
                           className='link-secondary link-underline-opacity-0' >
                          {assure.Structure?assure.Structure.intitule:null }
                    </Link> 
                  </td>
              <td>
                <span onClick={()=> HandleUpdate(assure) } className='btn btn-primary me-1'>
                             <CiEdit  size={35}  />
                 </span>
                <Link to={`../${appRoutes.profile_assure}`} 
                       state={assure} 
                       className='btn btn-secondary m-1'> 
                                   <GrFormView size={35} />
                </Link>
                <span onClick={()=>HandleDelete(assure.id)  } className='btn btn-danger m-1 me-1' >
                     <MdOutlineDeleteOutline size={30}  />
                </span>
              </td> 
            </tr>
        )) }
       
       
      </tbody>
    </Table>
  
    </div>
    )
}

export default AssureTable