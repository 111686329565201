
import React, { useEffect } from 'react'
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { GrFormView } from "react-icons/gr";
import { API } from '../../../api/API';
import { Link, useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';

const TableAdminAssurance = () => {
    const [admins, setadmins] = useState([]) ;
    const navigate =useNavigate()
    

    useEffect(() => {
         //call getadmin fonction here and setadmin
         getadmins()
    }, []) 
    

    const getadmins = async () => {
      try {
          const res = await API.get(`asssurance/admins?limit=${20}`);
          const { data, total } = res.data;
          setadmins(data);
        
      } catch (error) {
          console.log(`Error fetching data: ${error}`);
      }
  }
    const HandleUpdate=(element)=>{
        try {
          navigate(`../${appRoutes.register_admin}`,{state:{admin:element}})
        } catch (error) {
          console.log(`error ${error}`)
        }
        
    }
    const HandleView=(element)=>{
      try {
        navigate(`../${appRoutes.profile_admin}`, {state: { admin: element } })
      } catch (error) {
        console.log(`error ${error}`)
      }
     
    }


    const HandleDelete=async (id)=>{
      try {       
        const resp=await API.delete(`asssurance/admin/${id} `);
        getadmins() ;
       } catch (error) {
        console.log(`some error occure! ${error}  `)
      }
        
    }


    return (
        <div>
        <Table striped bordered hover>
        <thead>
        <tr>
          <th>#</th>
          <th>nom</th>
          <th>prenom</th>
          <th>email</th>
          <th>telephone</th>  
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {admins.map((admin,index)=>(
              <tr>
              <td>{`${index+1}`} </td>
              <td>{admin.nom }</td>
              <td>{admin.prenom }</td>
              <td>{admin.email }</td> 
              <td>{admin.tel }</td> 
            
           
           
              <td>
                <span onClick={()=> HandleUpdate(admin) } className='btn btn-primary m-1 me-1'>
                             <CiEdit  size={35}  />
                 </span>
                <span onClick={()=>HandleView(admin) }  className='btn btn-secondary m-1 me-1'> 
                                   <GrFormView size={35} />
                </span>
                <span onClick={()=>HandleDelete(admin.id)  } className='btn btn-danger m-1 me-1' >
                     <MdOutlineDeleteOutline size={30}  />
                </span>
              </td> 
            </tr>
        )) }
       
       
      </tbody>
    </Table>
  
    </div>
    )
}

export default TableAdminAssurance