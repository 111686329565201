import React, { useEffect, useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Appname, appColors, appRoutes, appSizes } from '../../constant/constant';
import { FaCircleUser } from "react-icons/fa6";
import { IoSearchCircleSharp } from "react-icons/io5";
import useUserStore from '../../store/store';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TopBar = () => { 
  const {user,updateAll, token}= useUserStore()
 

 
  const handleRecherche =()=>{

  }
  const handleDeconnexion=()=> {
    updateAll(null,null,false)
  }
  
  return (
    <div className='topbar pt-2 position-fixed end-0 start-0 top-0 ' >
      
        <Navbar  className="mb-3"  >
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand`}
              aria-labelledby={`offcanvasNavbarLabel-expand`}
              placement="end"
               >
              <Offcanvas.Header closeButton className='bg-success'>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand`} className='text-light'>
                  {Appname}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className='d-flex flex-column flex-sm-row justify-content-around '>
                
               <div className="form">
                  <Form onSubmit={handleRecherche}> 
                      <Form.Group md="4 " className='d-flex flex-row '>
                        
                          <Form.Control
                            required
                            type="text"
                            placeholder="Recherche"
                          
                            className='rounded-end-0'
                           />
                           <button className='btn btn-light p-0 rounded-start-0 border-start-0' type='submit' >
                                              <IoSearchCircleSharp size={appSizes.icon} color={appColors.light} />
                            </button>
                          
                     </Form.Group>
                  </Form>
               </div>
            
              
                    {user?
                     <div className="user  ">
                      <div className='d-flex felx-row align-items-center bg-success mb-0' >
                        <Link to={`${appRoutes.monProfil}`} > 
                                {(!user.image )?
                                    <FaCircleUser color={appColors.light} size={'40px'} className='' />:<img src={`${user.image} `} alt={user.name} />
                                      }    
                          </Link>            
                         <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {user.fullName} 
                                </Dropdown.Toggle>
                          
                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                  <Dropdown.Item onClick={handleDeconnexion} >Deconnexion </Dropdown.Item>
                                 
                                </Dropdown.Menu>
                        </Dropdown>                  
                    
                      </div>
                      
                   </div>:null}
                   
             
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
     
    </div>
  )
}

export default TopBar
