const Appname ="LaafiSeeba" ;


const appColors={
    primary:'#EB1616' ,
    secondary:'#191C24',
    light: '#6C7293',
    dark: '#000000',
}

const appSizes={
    icon :'45px',
    image:'45px'
}

const appRoutes ={
    home:"home",
    adminLogin:'login',
    adminConnect:'connect',
    adminAssure:'assure',
    adminMedecinConseil:'medecinconseille',
    adminAdmin:'adminassurance',
    adminParenaire:'Partenaires',
    clinique:'clinique',
    adminPharmacy:'Pharmacy',
    adminLaboratoire:'Laboratoire',
    adminPolice:'Police',
    Agent:'agent',
    Admi:'Admin',
    incription:'inscription',
    login:'login',
    register_assure:'register-assure',
    register_structure: 'register-structure',
    register_rfid_identify: 'register-rfid-identify',
    register_reclammation: 'register-reclammation',
    profile_assure:'profile-assure',
    register_partenaire:'register-partenaire',
    operation_medical:'aperation-medical',
    maladie:"maladie",
    categorie:"categorie",
    operation:'operation',
    register_categorie:"register-categorie",
    offre:'offre',
    register_maladie:'register_maladie',
    register_offre:'register-offre',
    register_police:'register-police',
    profile_police:'profile-police',
    strucuture_profile:'strucuture-profile',
    register_agent:'register-agent',
    profile_agent:'profile-agent',
    profile_agent_clinique:'profile-agent-clinique',
    profile_prescription:'profile-prescription',
    register_operation_medical:'register-operation-medical',
    register_operations:'register-operations',
    structure:'structure',
    profile_structure:'profile-structure',
    changePassWord:'change-password',
    monProfil:'mon-profil'
} ;


const appString ={
    laafiseebAuth:'laafiseebAuth',
    laafiseebUserMail:'laafiseebUserMail',
    USER_LOCAL_STORE:'userStore',
    assuranceMedecinVersion:'Assurance/medecin'
}
export {Appname,appColors,appSizes,appRoutes,appString} ;