import React, { useState, useEffect } from 'react';
import AddMedecinConseille from './AddMedecinConseille';
//import TopBar from '../TopBar';
import DynamicTable from '../../../global/components/DynamicTable';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { ApiService } from '../../../api/API';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';
import TableMedecinAssurance from '../Tables/TableMedecinAssurance';

const MedecinConseille = () => {

 
  return (
    <div className='p-3 p ' >
        <div className='mb-2' >
                 <Link to={`../${appRoutes.register_agent}`}  state={{type:'Medecin'}} className='btn bg-success text-light '>Ajouter</Link>
             </div>
             <TableMedecinAssurance />
      </div>
  );
};

export default MedecinConseille;
