import Assure from "../admin/components/Assure";
import Categorie from "../admin/components/Categorie";
import Maladie from "../admin/components/Maladie";
import OperationMedical from "../admin/components/OperationMedicales";
import Structure from "../admin/components/Structure";
import RegisterAssure from "../admin/components/forms/RegisterAssure";
import RegisterCategorie from "../admin/components/forms/RegisterCategorie";
import RegisterMaladie from "../admin/components/forms/RegisterMaladie";
import RegisterOPeration from "../admin/components/forms/RegisterOperation";
import RegisterPartenaire from "../admin/components/forms/RegisterPartenaire";
import RegisterPoliceAssurance from "../admin/components/forms/RegisterPoliceAssurance";
import RegisterStructure from "../admin/components/forms/RegisterStructure";
import ProfilAgent from "../admin/components/profilAgent";
import ProfilAssure from "../admin/components/profiles/Assureprofile";
import ProfilePrescription from "../admin/components/profiles/ProfilPrescriptiont";
import ProfilStructure from "../admin/components/profiles/ProfilStructure";
import ProfilePolice from "../admin/components/profiles/ProfilePolice";
import Structureprofile from "../admin/components/profiles/Structureprofile";
import HomeAgent from "../agents/pages/HomeAgent";
import { appRoutes } from "../constant/constant";
import NotFoundPage from "../global/NotFoundPage";

const agentRoute =[
    {
        path: "",
        element:<HomeAgent />,
        children: [
        
            {
                path: appRoutes.adminAssure,
                element: <Assure/>,
              },
              {
                path:appRoutes.register_assure,
                element:<RegisterAssure/>
              },
              {
                path:appRoutes.profile_assure,
                element:<ProfilAssure />
              },
              {
                path:appRoutes.register_partenaire,
                element:<RegisterPartenaire />
              },
              {
                path:appRoutes.categorie,
                element:<Categorie />
              },
              {
                path:appRoutes.maladie,
                element:<Maladie />
              },
              {
                path:appRoutes.operation_medical ,
                element:<OperationMedical />
              },
              {
                path:appRoutes.register_categorie,
                element:<RegisterCategorie />
              },
              {
                path:appRoutes.register_maladie,
                element:<RegisterMaladie/>
              },
              {
                path:appRoutes.profile_assure,
                element:<ProfilAssure />
              },
              {
                path:appRoutes.profile_police,
                element:<ProfilePolice />
              },
              {
                path:appRoutes.strucuture_profile,
                element:<Structureprofile />
              },
              {
                path:appRoutes.profile_prescription,
                element:<ProfilePrescription />
              },
              {
                path:appRoutes.register_police,
                element:<RegisterPoliceAssurance />
              },
              {
                path:appRoutes.register_operations,
                element:<RegisterOPeration />
              },
              {
                path:appRoutes.structure,
                element:<Structure/>
              },
              {
                path:appRoutes.register_structure,
                element:<RegisterStructure/>
              },
              {
                path:appRoutes.profile_structure,
                element:<ProfilStructure/>
              },
              {
                path:appRoutes.profile_agent,
                element:<ProfilAgent/>
              },
              {
                path: '*',
                element: <NotFoundPage />,
              },
        ],
      },

]

export default agentRoute ;