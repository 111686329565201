import React from 'react'
import { Table } from 'react-bootstrap'

const TableTemplatemaladie = ({maladies}) => {
    return (
        <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Label</th>
                        <th>Description</th>
                       
                    </tr>
                </thead>
                <tbody>
                    {maladies.map((maladie, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{maladie.label}</td>
                            <td>{maladie.description}</td>
                           
                        </tr>
                    ))}
                </tbody>
            </Table>
    )
}

export default TableTemplatemaladie