import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { object, string, InferType } from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { API } from '../../../api/API';


const RegisterOPeration = () => {
const [responseMessage, setResponseMessage] = useState(null);
 const navigate =useNavigate()
    const maladieSchema = object({
        label: string().required(),
        description: string()
    });


    return (
      
            <div className='opacity-75 bg-light rounded-4 p-4'  style={{maxWidth:'80%'}}>
                <p className='m-2 fs-2' >Enregister une nouvelle maladie</p>
                <Formik
                    validationSchema={maladieSchema}
                    initialValues={{ label: '', description: '' }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            // Handle successful submission
                            const resp= await API.post('/operation_medicale',values) ;
                             setResponseMessage(resp.data.message)
                            setSubmitting(false);
                        } catch (error) {
                            if (error.response) {
                                // La requête a été faite et le serveur a répondu avec un code de statut
                                // qui n'est pas dans la plage 2xx
                                setResponseMessage(`${JSON.stringify(error.response.status) } ${JSON.stringify(error.response.data.error)}`);
                            } else if (error.request) {
                                console.error('Error request:', error.request);
                            } else {
                                // Quelque chose s'est passé lors de la configuration de la requête qui a déclenché une erreur
                                console.error('Error message:', error.message);
                            }
                            console.error('Error config:', error.config);
                            // Handle error
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ values, errors,resetForm, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                            {responseMessage? <Row> <span className='bg-success-subtle' >{responseMessage} </span> </Row>:null}
                                <Form.Group as={Col}>
                                    <Form.Label>Label</Form.Label>
                                    <Form.Control
                                        name="label"
                                        required
                                        type="text"
                                        value={values.label}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Label"
                                        isInvalid={touched.label && !!errors.label}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col}>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        name="description"
                                        type="text"
                                        placeholder="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.description && !!errors.description}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit" className='bg-success mt-3 me-4  ' disabled={isSubmitting}>Enregistrer</Button>
                            <Button  className='bg-secondary mt-3 me-4' onClick={()=>resetForm() } >clear</Button>
                            <Button  className='bg-warning mt-3 ' onClick={()=>{ navigate(-1) }} >Quitter</Button>
                        </Form>
                    )}
                </Formik>
            </div>
      
    );
}

export default RegisterOPeration;
