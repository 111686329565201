
import React from 'react'

import TablePrescription from './Tables/TablePrescription';


const HomeDashbord = () => {


    return (
            <div className=' m-1 p-2' >        
               
                    <TablePrescription/>
          
           </div>
               
    )
}

export default HomeDashbord