
import React, { useEffect} from 'react';
import SideBar from '../components/SideBar';
import { Outlet, useNavigate } from 'react-router-dom';
import TopBar from '../components/TopBar';    
import useUserStore from '../../store/store';


const Home=()=> {
  const navigate=useNavigate()
  const { isAuth}= useUserStore()
   useEffect(() => {
      if (!isAuth) {
      //  navigate('../', { replace: true })
      }
   }, [isAuth])
   
 

  return (
        <div className='m-0 p-0 h-100'>
           <SideBar />
           <TopBar/>
           
           <div className='content'>
                      <Outlet/>
            </div>     
        </div>
      );
    };
    
   
export default Home
