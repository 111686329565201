import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { object, string } from 'yup';
import { Formik } from 'formik';
import { API } from '../../../api/API';
import { useNavigate } from 'react-router-dom';
import { InputGroup } from 'react-bootstrap';

const RegisterStructure = () => {
    const [responseMessage, setResponseMessage] = useState(null);
    const navigate = useNavigate();
    const [code, setCode] = useState(null);

    useEffect(() => {
        API.get("/stucture_code").then((res) => {
            const code = res.data.code;
            setCode(code);
            console.log(code);
        }).catch(error => {
            console.error('Error fetching code:', error);
        });
    }, []);

    const structureSchema = object({
        intitule: string().required(),
        code: string().required(),
        tel: string(),
        adresse: string(),
        email: string().email().required(),
        image: string(),
        type: string()
    });

    return (
        <div className='opacity-75 bg-light rounded-4 p-4 m-2'>
            <p className='fs-1 m-1'>Enregistrer une structure</p>
            <Formik
                validationSchema={structureSchema}
                initialValues={{ intitule: '', code: '', tel: '', adresse: '', email: '', image: '', type: '' }}
                onSubmit={async (values, { setSubmitting }) => {
                    console.log('submit submit');
                    try {
                        const resp = await API.post('/structure', values);
                        setResponseMessage(resp.data.message);
                        setSubmitting(false);
                    } catch (error) {
                        if (error.response) {
                            setResponseMessage(`${JSON.stringify(error.response.status)} ${JSON.stringify(error.response.data.error)}`);
                        } else if (error.request) {
                            console.error('Error request:', error.request);
                        } else {
                            console.error('Error message:', error.message);
                        }
                        console.error('Error config:', error.config);
                        setSubmitting(false);
                    }
                }}
            >
                {({ values, errors, resetForm, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {responseMessage ? <Row> <span className='bg-success-subtle'>{responseMessage}</span> </Row> : null}
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Intitulé</Form.Label>
                                <Form.Control
                                    name="intitule"
                                    required
                                    type="text"
                                    value={values.intitule}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Intitulé"
                                    isInvalid={touched.intitule && !!errors.intitule}
                                />
                                <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Code</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className='bg-success text-light'
                                        onClick={() => { setFieldValue('code', code); }}
                                    >
                                        Obtenir code
                                    </InputGroup.Text>
                                    <Form.Control
                                        name="code"
                                        required
                                        type="text"
                                        value={values.code}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Code"
                                        isInvalid={touched.code && !!errors.code}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col}>
                                <Form.Label>Téléphone</Form.Label>
                                <Form.Control
                                    name="tel"
                                    type="text"
                                    value={values.tel}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Téléphone"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Adresse</Form.Label>
                                <Form.Control
                                    name="adresse"
                                    type="text"
                                    value={values.adresse}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Adresse"
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    name="email"
                                    required
                                    type="text"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.email && !!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">Entrez un email valide</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                    name="image"
                                    type="file"
                                    placeholder="Image"
                                    onChange={(event) => {
                                        setFieldValue('image', event.currentTarget.files[0]);
                                    }}
                                    onBlur={handleBlur}
                                />
                            </Form.Group>
                        </Row>
                        <Button type="submit" className='bg-success mt-3 me-4' disabled={isSubmitting}>Enregistrer</Button>
                        <Button className='bg-secondary mt-3 me-4' onClick={() => resetForm()}>Clear</Button>
                        <Button className='bg-warning mt-3' onClick={() => { navigate(-1) }}>Quitter</Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default RegisterStructure;
