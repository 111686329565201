import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import Row from 'react-bootstrap/Row';
import { object, string, boolean, InferType } from 'yup';
import { Formik } from 'formik';
import { API } from '../../../api/API';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';



const RegisterAgentAssurance = () => {
    const [responseMessage, setResponseMessage] = useState(null);
     const navigate =useNavigate();
    const location =useLocation() ;

    const agentAssuranceSchema = object({
        nom: string().required(),
        prenom: string().required(),
        email: string().email().required(),
        tel: string().required(),
        image: string().nullable(),
        type: string().required().oneOf(['agent', 'admin','medecin']),
    });
  


    return (
   
            <div className='opacity-75 bg-light rounded-4 p-4 m-2 '>
                 <p className='h6 fs-2 fw-bold' >Creer un {location.state.type}</p>
                <Formik
                    validationSchema={agentAssuranceSchema}
                    initialValues={{ nom: '', prenom: '', tel: '', email: '', password: '', image: null, type: location.state.type ||'Agent' }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            // Handle successful submission
                            const resp= await API.post('asssurance/agent',values) ;
                             setResponseMessage(resp.data.message)
                            setSubmitting(false);
                        } catch (error) {
                            if (error.response) {
                                // La requête a été faite et le serveur a répondu avec un code de statut
                                // qui n'est pas dans la plage 2xx
                                setResponseMessage(`${JSON.stringify(error.response.status) } ${JSON.stringify(error.response.data.error)}`);
                            } else if (error.request) {
                                console.error('Error request:', error.request);
                            } else {
                                // Quelque chose s'est passé lors de la configuration de la requête qui a déclenché une erreur
                                console.error('Error message:', error.message);
                            }
                            console.error('Error config:', error.config);
                            // Handle error
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ values, errors, touched,resetForm, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                              {responseMessage? <Row> <span className='bg-success-subtle' >{responseMessage} </span> </Row>:null}
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control
                                        name="nom"
                                        required
                                        type="text"
                                        value={values.nom}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Votre nom"
                                        isInvalid={touched.nom && !!errors.nom}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Control
                                        name="prenom"
                                        required
                                        type="text"
                                        value={values.prenom}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Prénom"
                                        isInvalid={touched.prenom && !!errors.prenom}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="text"
                                        placeholder="Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.email && !!errors.email}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Entrez un email valide</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Tel: </Form.Label>
                                    <Form.Control
                                        name="tel"
                                        type="text"
                                        placeholder="Numéro de téléphone"
                                        value={values.tel}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.tel && !!errors.tel}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Entrez un numéro valide</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                               
                                <Form.Group as={Col}>
                                    <Form.Label>Type:</Form.Label>
                                    <Form.Control
                                        name="type"
                                        as="select"
                                        value={values.type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.type && !!errors.type}
                                        required
                                    >
                                        <option value="agent">Agent</option>
                                        <option value="admin">Admin</option>
                                        <option value="medecin">Medecin</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">Sélectionnez un type valide</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} className="position-relative mb-3">
                                    <Form.Label>Image de profil: </Form.Label>
                                    <Form.Control
                                        name="image"
                                        type="file"
                                        onChange={handleChange}
                                        isInvalid={touched.image && !!errors.image}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.image}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                           
                            <Button type="submit" className='bg-success mt-3 me-4  ' disabled={isSubmitting}>Enregistrer</Button>
                            <Button  className='bg-secondary mt-3 me-4' onClick={()=>resetForm() } >clear</Button>
                            <Button  className='bg-warning mt-3 ' onClick={()=>{ navigate(-1) }} >Quitter</Button>
                        </Form>
                    )}
                </Formik>
            </div>

    );
}

export default RegisterAgentAssurance;
