import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { API } from '../../../api/API';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';

const TableOperationMedical = () => {
    const [operations, setOperations] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchOperations();
    }, []);

    const fetchOperations = async () => {
        try {
            const res = await API.get('/operation_medicales');
            const data = res.data;
            
            setOperations(data.data);
        } catch (error) {
            console.log(`Error fetching data: ${error}`);
        }
    }

    const handleUpdate = (operation) => {
        navigate(`../${appRoutes.register_operations}`, { state:operation });
    }


    const handleDelete =async  (id) => {
        // Handle delete logic
        try {       
            const resp=await API.delete(`/operation_medicale/${id} `);
           fetchOperations();
           } catch (error) {
            console.log(`some error occure! ${error}  `)
          }
    }

    return (
        <div>
            <p className='fs-1' >Operation medicales</p>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Label</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {operations.map((operation, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{operation.label}</td>
                            <td>{operation.description}</td>
                            <td>
                            <span className='btn btn-primary me-1' onClick={() => handleUpdate(operation)}><CiEdit size={35} /></span>
                                
                                <span  className='btn btn-danger me-1' onClick={() => handleDelete(operation.id)}><MdOutlineDeleteOutline size={30} /></span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default TableOperationMedical;
