import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { object, string, InferType } from 'yup';
import { Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { API } from '../../../api/API';
import { InputGroup } from 'react-bootstrap';



const RegisterPartenaire = () => {
    const navigate = useNavigate();
    const location =useLocation() ;
    const [type, settype] = useState('partenaire') ;
    const [responseMessage, setResponseMessage] = useState(null);
    const [code, setcode] = useState(null) ;
    const [initialValues, setinitialValues] = useState({ intitule: '', code: '', tel: '', adresse: '', email: '', type: (type==='partenaire')?'clinique':'type' })

    useEffect(() => {
      location.state && settype(location.state.type);
      const id=location.state.id ;
      if (id) {
        API.get(`/partenaire/${id}`).then(res=>{
          const data= res.data ;
          console.log(data)
          const { ...partenaire} =data ;
        }).catch(error=>{
            console.log('error')
        })
      }
    }, [location]) ;

    useEffect(() => {
      API.get(`/partenaire/getcode`).then((res)=>{
        const code =res.data.code ;
      
        setcode(code );
      }).catch((error)=>{
        console.log('code1 ', error)
      })
    }, [])

    const partenaireSchema = object({
        intitule: string().required(),
        code: string().required(),
        tel: string().required(),
        adresse: string(),
        email: string().email().required(),
        type: string().oneOf(['clinique', 'pharmacy', 'laboratoire', 'clinique_laboratoire']).required()
    });

   
    return (
        
            <div className='opacity-75 bg-light rounded-4 m-2 p-4'>
            <div className='m-1 fs-1' >Enregistrer un nouveau {type } </div>
                <Formik
                    validationSchema={partenaireSchema}
                    initialValues={initialValues}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            // Handle successful submission
                            const resp= await API.post('/partenaire',values) ;
                             setResponseMessage(resp.data.message)
                            setSubmitting(false);
                        } catch (error) {
                            if (error.response) {
                                // La requête a été faite et le serveur a répondu avec un code de statut
                                // qui n'est pas dans la plage 2xx
                                setResponseMessage(`${JSON.stringify(error.response.status) } ${JSON.stringify(error.response.data.error)}`);
                            } else if (error.request) {
                                console.error('Error request:', error.request);
                            } else {
                                // Quelque chose s'est passé lors de la configuration de la requête qui a déclenché une erreur
                                console.error('Error message:', error.message);
                            }
                            console.error('Error config:', error.config);
                            // Handle error
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ values, errors, resetForm,touched,setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {responseMessage? <Row> <span className='bg-success-subtle'>{responseMessage} </span> </Row>:null}
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Intitulé</Form.Label>
                                    <Form.Control
                                        name="intitule"
                                        required
                                        type="text"
                                        value={values.intitule}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Intitulé"
                                        isInvalid={touched.intitule && !!errors.intitule}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Code</Form.Label>
                                    <InputGroup>
                                    <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className='bg-success text-light'
                                        onClick={() => { setFieldValue('code', code); }}
                                        >
                                        Obtenir code
                                    </InputGroup.Text>
                                    
                                    <Form.Control
                                        name="code"
                                        required
                                        type="text"
                                        value={values.code}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Code"
                                        isInvalid={touched.code && !!errors.code}
                                    />

                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col}>
                                    <Form.Label>Téléphone</Form.Label>
                                    <Form.Control
                                        name="tel"
                                        type="text"
                                        placeholder="Téléphone"
                                        value={values.tel}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Adresse</Form.Label>
                                    <Form.Control
                                        name="adresse"
                                        type="text"
                                        placeholder="Adresse"
                                        value={values.adresse}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="text"
                                        placeholder="Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.email && !!errors.email}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Entrez un email valide</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control
                                        name="type"
                                        as="select"
                                        value={values.type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.type && !!errors.type}
                                        required
                                    >
                                        <option value="clinique">Clinique</option>
                                        <option value="pharmacy">Pharmacie</option>
                                        <option value="laboratoire">Laboratoire</option>
                                        <option value="clinique_laboratoire">Clinique et Laboratoire</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">Sélectionnez un type</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit" className='bg-success mt-3 me-4  ' disabled={isSubmitting}>Enregistrer</Button>
                            <Button  className='bg-secondary mt-3 me-4' onClick={()=>resetForm() } >clear</Button>
                            <Button  className='bg-warning mt-3 ' onClick={()=>{ navigate(-1) }} >Quitter</Button>                        </Form>
                    )}
                </Formik>
            </div>
     
    );
}

export default RegisterPartenaire;
