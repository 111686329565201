import React from 'react'
import { Link } from 'react-router-dom'
import { appRoutes } from '../../constant/constant'
import TableCategorie from './Tables/TableCategorie'

const Categorie = () => {
    return (
        <div className='p-2 ' >
        <div className='m-2 ' >
            <Link to={`../${appRoutes.register_categorie}`}  className='btn bg-success ' >Ajouter</Link>
        </div>
        <TableCategorie  />
    </div>
    )
}

export default Categorie