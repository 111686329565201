import React from 'react'
import { Link } from 'react-router-dom'
import { appRoutes } from '../../constant/constant'
import TableOffre from './Tables/TableOffre'

const Offre = () => {
    return (
        <div className='p-2 ' >
               <div className='m-2 ' >
                   <Link to={`../${appRoutes.register_offre}`}  className='btn bg-success ' >Ajouter</Link>
               </div>
               <TableOffre />
         </div>
    )
}

export default Offre