import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { object, string, date } from 'yup';
import { Formik } from 'formik';
import {  API } from '../../../api/API';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputGroup } from 'react-bootstrap';
 
const RegisterAssure = () => {
   const [Polices, setPolices] = useState([]) ;
   const [Structure, setStructure] = useState([]) ;
   const [responseMessage, setResponseMessage] = useState(null);
   const navigate =useNavigate() ;

    const assureSchema = object({
        nom: string().required(),
        prenom: string().required(),
        email: string().email(),
        tel: string().required(),
        adresse: string(),
        matricule: string().required(),
        rfid:string(),
        cni: string(),
        date_naissance: date().nullable().required(),
        idPolice: string().required(),
        matricule_resp: string(),
        idStructure: string().required() // Validation for structure selection
    });

    useEffect(() => {
      API.get('/structures').then((res)=>{
        const data =res.data ;
        setStructure(data.data)
      }).catch((error)=>{
         console.log(`error `)
      });
     API.get('/polices').then((res)=>{
        const data =res.data ;
         setPolices(data.data)
     }).catch(e=>{
        console.log('error')
     }) ;

    }, [])
    
    const getMatricule =(structureId,setFieldValue)=>{
      API.get(`stucture/matricule/${structureId}`).then(res=>{
        const matricule =res.data.suggestedMatricule ;
        setFieldValue('matricule',matricule)
      }).catch((error)=>{
        console.log("error")
      })
    }

    return (
            <div className='register-page opacity-75 bg-light rounded-4 p-4 '>
                <p className='h6 fs-2 fw-bold' >Creer un Assuré</p>
                <Formik
                    validationSchema={assureSchema}
                    initialValues={{ nom: '', prenom: '', tel: '', adresse: '', email: '', matricule: '', password: '', cni: '', date_naissance: null, idPolice: '', matricule_resp: '', idStructure: '' }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            // Handle successful submission
                            const resp= await API.post('/assure',values) ;
                             setResponseMessage(resp.data.message)
                            setSubmitting(false);
                        } catch (error) {
                            if (error.response) {
                                // La requête a été faite et le serveur a répondu avec un code de statut
                                // qui n'est pas dans la plage 2xx
                                setResponseMessage(`${JSON.stringify(error.response.status) } ${JSON.stringify(error.response.data.error)}`);
                            } else if (error.request) {
                                console.error('Error request:', error.request);
                            } else {
                                // Quelque chose s'est passé lors de la configuration de la requête qui a déclenché une erreur
                                console.error('Error message:', error.message);
                            }
                            console.error('Error config:', error.config);
                            // Handle error
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ values, errors,setFieldValue, touched, handleChange, handleBlur, handleSubmit,resetForm, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {responseMessage? <Row> <span className='bg-success-subtle' >{responseMessage} </span> </Row>:null}
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control
                                        name="nom"
                                        required
                                        type="text"
                                        value={values.nom}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Votre nom"
                                        isInvalid={touched.nom && !!errors.nom}
                                      />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Control
                                        name="prenom"
                                        required
                                        type="text"
                                        value={values.prenom}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Prénom"
                                        isInvalid={touched.prenom && !!errors.prenom}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="text"
                                        placeholder="Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.email && !!errors.email}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Entrez un email valide</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Tel: </Form.Label>
                                    <Form.Control
                                        name="tel"
                                        type="text"
                                        placeholder="Numéro de téléphone"
                                        value={values.tel}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.tel && !!errors.tel}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Entrez un numéro valide</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                               <Form.Group as={Col}>
                                    <Form.Label>Structure:</Form.Label>
                                    <Form.Control
                                        name="idStructure"
                                        as="select"
                                        value={values.idStructure}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.idStructure && !!errors.idStructure}
                                        required
                                        className='fw-bolder'
                                        >
                                        <option value="">Sélectionnez une structure</option>
                                        {Structure.map(s => (
                                            <option key={s.id} value={s.id}>{s.intitule}</option>
                                        ))}
                                        {/* Ajoutez les options de structure ici */}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">Sélectionnez une structure</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Police:</Form.Label>
                                    <Form.Control
                                        name="idPolice"
                                        as="select"
                                        value={values.idPolice}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.idCategorie && !!errors.idCategorie}
                                        required
                                        className='fw-bolder'
                                          >
                                        <option value="">Sélectionnez une  police</option>
                                        {Polices.map(police => (
                                            <option key={police.id} value={police.id}>{police.libelle}</option>
                                        ))}
                                        {/* Ajoutez les options de catégorie ici */}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">Sélectionnez une police</Form.Control.Feedback>
                                </Form.Group>

                               </Row>
                               <Row> 
                                <Form.Group as={Col}>
                                    <Form.Label>Matricule</Form.Label>
                                    <InputGroup>
                                    <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className='bg-success text-light'
                                        onClick={() => { 
                                            const{ idStructure} =values ;
                                            if (idStructure && idStructure!=='') {
                                                getMatricule(idStructure,setFieldValue);
                                            }else{
                                                alert("aucune structure n'a été selectionner !")
                                            }
                                          
                                         }}
                                      >
                                        suggest matricule
                                    </InputGroup.Text>
                                    <Form.Control
                                        name="matricule"
                                        type="text"
                                        placeholder="Matricule"
                                        value={values.matricule}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.matricule && !!errors.matricule}
                                        required
                                    />
                                    </InputGroup>
                                    
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                           
                                <Form.Group as={Col}>
                                    <Form.Label>Date de naissance:</Form.Label>
                                    <Form.Control
                                        name="date_naissance"
                                        type="date"
                                        placeholder="Date de naissance"
                                        value={values.date_naissance}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.date_naissance && !!errors.date_naissance}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                               
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Matricule responsable:</Form.Label>
                                    <Form.Control
                                        name="matricule_resp"
                                        type="text"
                                        placeholder="Matricule responsable"
                                        value={values.matricule_resp}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.matricule_resp && !!errors.matricule_resp}
                                    />
                                    <Form.Control.Feedback type="invalid">Entrez un matricule valide</Form.Control.Feedback>
                                </Form.Group>
                                
                                <Form.Group as={Col}>
                                    <Form.Label>CNI:</Form.Label>
                                    <Form.Control
                                        name="cni"
                                        type="text"
                                        placeholder="CNI"
                                        value={values.cni}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.cni && !!errors.cni}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row>
                            <Form.Group as={Col} >
                                    <Form.Label>Adresse</Form.Label>
                                    <Form.Control
                                        name="adresse"
                                        type="text"
                                        placeholder="Adresse"
                                        value={values.adresse}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.adresse && !!errors.adresse}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} >
                                <Form.Label>Numero rfid</Form.Label>
                                    <Form.Control
                                        name="rfid"
                                        type="text"
                                        placeholder="numero de la carte rfid"
                                        value={values.rfid}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.rfid && !!errors.rfid}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit" className='bg-success mt-3 me-4  ' disabled={isSubmitting}>Enregistrer</Button>
                            <Button  className='bg-secondary mt-3 me-4' onClick={()=>resetForm() } >clear</Button>
                            <Button  className='bg-warning mt-3 ' onClick={()=>{ navigate(-1) }} >Quitter</Button>
                        </Form>
                    )}
                </Formik>
            </div>
       
    );
}

export default RegisterAssure;
