import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { object, string, InferType } from 'yup';
import { Formik } from 'formik';



const RegisterCategorie = () => {

    // Mock data for polices d'assurance
    const [polices, setPolices] = useState([
        { id: 1, nom: "Police 1" },
        { id: 2, nom: "Police 2" }
    ]);

    const categorieSchema = object({
        cout: string().required(),
        description: string(),
        numero: string().required(),
        idPolice: string().required() // Validation for police selection
    });

    

    return (
        <div className='login-page w-100 container-fluid h-100 d-flex align-items-center justify-content-center'>
            <div className='opacity-75 bg-light rounded-4 p-4'>
                <Formik
                    validationSchema={categorieSchema}
                    initialValues={{ cout: '', description: '', numero: '', idPolice: '' }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                           
                            setSubmitting(false);
                        } catch (error) {
                            console.error(error);
                            // Handle error
                            setSubmitting(false);
                        }
                    }}
                   >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Coût</Form.Label>
                                    <Form.Control
                                        name="cout"
                                        required
                                        type="text"
                                        value={values.cout}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Coût"
                                        isInvalid={touched.cout && !!errors.cout}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        name="description"
                                        type="text"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Description"
                                        isInvalid={touched.description && !!errors.description}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col}>
                                    <Form.Label>Numéro</Form.Label>
                                    <Form.Control
                                        name="numero"
                                        type="text"
                                        placeholder="Numéro"
                                        value={values.numero}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.numero && !!errors.numero}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Police d'assurance</Form.Label>
                                    <Form.Control
                                        name="idPolice"
                                        as="select"
                                        value={values.idPolice}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.idPolice && !!errors.idPolice}
                                        required
                                    >
                                        <option value="">Sélectionnez une police</option>
                                        {polices.map(police => (
                                            <option key={police.id} value={police.id}>{police.nom}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">Sélectionnez une police</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit" className='bg-danger' disabled={isSubmitting}>Enregistrer</Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default RegisterCategorie;
