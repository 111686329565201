import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import formatDateTime from '../../../utils/dateFormate';
import { Row, Col, Card, Button } from 'react-bootstrap';
import useUserStore from '../../../store/store';
import { API } from '../../../api/API';

const ProfilePrescription = () => {
    const location = useLocation();
    const [prescription, setPrescription] = useState(null);
    const [agentClinique, setagentClinique] = useState(null)
    const {user}=useUserStore();

    useEffect(() => {
        if (location.state) {
            setPrescription(location.state);
            
            if (location.state.id) {
                const idPrescription =location.state.id ;
                getPrescription(idPrescription) ;
            }

            if (location.state.agent_clinique) {
                const {id} =location.state.agent_clinique ;
        
                getClinique_from_Medecin(id) ;
            }
           
        }
    }, [location.state]);
  
     
     const getClinique_from_Medecin= (idMedecin)=>{
        API.get(`/clinique/agent/${idMedecin}`).then(res=>{
          const cliniqueAgent=  res.data.data ;
          setagentClinique(cliniqueAgent);
        // console.log("cliniqueAgent",cliniqueAgent)
        }).catch(error=>{
            console.log("error", error)
        })
     }
     const getPrescription =(id)=>{
        API.get(`/prescription/${id}`).then(res=>{
              const data =res.data ;
              setPrescription(data.data)
            //  console.log("prescription",data)
        }).catch(err=>{
            console.log("error get prescription",err) ;
        })
    }

    if (!prescription) {
        return <p>Loading...</p>;
    }

    const {
        titre,
        description,
        datePrescription,
        MedecinAssurance,
        agent_clinique,
        assure,
    } = prescription;

  

    const UpdateValidPrescription =(prescription)=>{
      if (user && user.id) {
        const {id,
            statusMedecinConseille,
            MedecinAssurance,
            assure,
            AgentAssurance,
            agent_clinique,
            statusAgentAssurance,
            ..._prescription}=prescription ;

          const new_prescription ={
             _prescription,
             statusAgentAssurance:!statusAgentAssurance ,
             idAgentAssurance:user.id,
             datePrescriptionAgentAssurance:new Date() 
          }
      
         API.put(`/prescription/${id}`,new_prescription).then((res)=>{
            getPrescription(id);
         }).catch((error)=>{
             console.log(`${JSON.stringify(error) }`)
         })
      }
     }
     const UpdateValidPrescription_by_medecin_conseil =(prescription)=>{
        if (user && user.id) {
            const {id,
                statusMedecinConseille,
                MedecinAssurance,
                assure,
                AgentAssurance,
                agent_clinique,
                ..._prescription}=prescription ;
      
    
         const new_prescription ={
          _prescription,
          statusMedecinConseille:!statusMedecinConseille,
          idMedecinAssurance:user.id,
          dateApprotionMedecinConseille:new Date() 
         }
        
       
         API.put(`/prescription/${id}`,new_prescription).then((res)=>{
               getPrescription(id);
           }).catch((error)=>{
               console.log(`${JSON.stringify(error) }`)
           })
        }
       }
   
    return (
        <div className='container-fluid  m-2 profil-bg p-1'>
            <h1 className='m-1 fs-1'>{prescription?titre:null}</h1>
            <p className='m-1'>{prescription?description:null}</p>
            <Card className='mt-4 p-3'>
                <Row>                    
                    <Col>
                       <small className='m-2'>
                           {`Prescription du ${formatDateTime(datePrescription)} `}
                        </small>
                         
                        <p className='m-2' >
                            <small className='border-end me-1'>
                                   { formatDateTime(prescription.dateApprotionMedecinConseille)} 
                            </small>
                             Status medecin Conseillé:
                              {(prescription.statusMedecinConseille)?
                                                      <strong className='text-success '>Approuveé</strong> :
                                                      <strong className='text-danger ' >Non approuver</strong> 
                                                                                            
                             } 
                        </p> 
                        <p className='m-2' >
                           <small className='border-end me-1'>
                                { formatDateTime(prescription.datePrescriptionAgentAssurance)}
                           </small>
                              Status agent assurance: 
                              {(prescription.statusAgentAssurance) ? 
                                      <span className='text-success'>Validé</span>
                                     :<span className='text-danger'>Non validé</span> 
                                 
                               }

                         </p> 
                         <span>
                            {
                                prescription.statusMedecinConseille ? (
                                    user.type==='medecin'?  
                                            <Button className='btn-danger m-1'
                                                     onClick={() => UpdateValidPrescription_by_medecin_conseil(prescription)}>
                                                      Annuler l'approbation
                                            </Button>                                   
                                    :null
                                    )
                                    :
                                     (user.type==='medecin'?<Button className='btn-warning m-1'
                                        onClick={() => UpdateValidPrescription_by_medecin_conseil(prescription)}>
                                       Approuver
                                   </Button>:null)
                            }
                            

                            {   
                              
                                   (prescription.statusAgentAssurance )?
                                             (user.type!=='medecin'?<Button className='btn btn-danger' 
                                                     onClick={() => UpdateValidPrescription(prescription)}>
                                                        Annuler la validation
                                             </Button>:null)
                                          :(user.type!=='medecin'?<Button className='btn btn-warning m-1'
                                                   onClick={() => UpdateValidPrescription(prescription)} >
                                                Valider
                                          </Button>:null)
                            }
                         </span>
                        
                    </Col>
                </Row>
            </Card>

            <Card className='mt-4 p-3'>
                <Row>
                {assure?<Col md={4} className='border-end'>
                        <h3 className='border-bottom'>Assuré</h3>
                        <p className='fw-bold' > {`${assure.nom} ${assure.prenom}`}</p>

                        <p><strong>Email:</strong> {assure.email}</p>
                        <p><strong>Adresse:</strong> {assure.adresse}</p>
                        <p><strong>Date de naissance:</strong> {formatDateTime(assure.date_naissance)}</p>
                        <p><strong>Matricule:</strong> {assure.matricule}</p>
                        <p><strong>Téléphone:</strong> {assure.tel}</p>
                    </Col>:null}
                    {MedecinAssurance?<Col md={4} className='border-end'>
                        <h3 className='border-bottom'> Médecin conseillé </h3>
                        <p className='fw-bold' >{`${MedecinAssurance.nom} ${MedecinAssurance.prenom}`}</p>
          
                        <p><strong>Email:</strong> {MedecinAssurance.email}</p>
                        <p><strong>Type:</strong> {MedecinAssurance.type}</p>
                    </Col>:null}
                   {agent_clinique? <Col md={4}>
                        <h3 className='border-bottom' >Medecin Prescipteur</h3>
                        <p className='fw-bold' > {`${agent_clinique.nom} ${agent_clinique.prenom}`}</p>
                     
                        <p><strong>Email:</strong> {agent_clinique.email}</p>
                        <p><strong>Téléphone:</strong> {agent_clinique.tel}</p>
                        {agentClinique?
                        <span>
                          <p><strong>Clinique:</strong> 
                                {agentClinique.Partenaire.intitule}</p>
                                     
                              <small>{agentClinique.Partenaire.email }</small>
                        </span>:null
                         }
                       
                    </Col>:null}
                </Row>
            </Card>
        </div>
    );
};

export default ProfilePrescription;
