// AddClinique.js
import React, { useState } from 'react';
import { Button, Modal, Form, Col } from 'react-bootstrap';
import axios from 'axios';

function AddClinique({ refreshData }) {
  const [show, setShow] = useState(false);
  const [libelle, setLibelle] = useState('');
  const [adresse, setAdresse] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [siege, setSiege] = useState('');

  const handleClose = () => {
    setShow(false);
    // Réinitialiser les champs après la fermeture du modal
    setLibelle('');
    setAdresse('');
    setEmail('');
    setTel('');
    setSiege('');
  };

  const handleShow = () => setShow(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Envoi des données au backend via une requête POST
      await axios.post('http://localhost:5000/api/v1/clinique', {
        libelle,
        adresse,
        email,
        tel,
        siege
      });
      // Rafraîchir les données après l'enregistrement
      refreshData();
      handleClose();
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la clinique:', error);
    }
  };

  return (
    <>
      <div className="">
        <Button variant="success" onClick={handleShow}>
          Ajouter
        </Button>
      </div>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-70w">
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une clinique</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Col>
              <Form.Group className="mb-3" controlId="formLibelle">
                <Form.Label>Libellé</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Entrez le libellé"
                  value={libelle}
                  onChange={(e) => setLibelle(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formAdresse">
                <Form.Label>Adresse</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Entrez l'adresse"
                  value={adresse}
                  onChange={(e) => setAdresse(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Entrez l'email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formTel">
                <Form.Label>Téléphone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Entrez le téléphone"
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formSiege">
                <Form.Label>Siège</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Entrez le siège"
                  value={siege}
                  onChange={(e) => setSiege(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Button variant="success" type="submit">
              Enregistrer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddClinique;
