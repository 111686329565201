import { Formik } from 'formik';
import React from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import { object, string } from 'yup';
import { appRoutes } from '../../constant/constant';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../api/API';
const ChangePassWord = () => {

    
  const userSchema = object({
    email: string().email().required(),
   
});
    return (
        <div className='login-page w-100 container-fluid h-100 d-flex ' style={{ backgroundColor: '#f9f9f9' }}>
        <div className='opacity-75 container bg-light align-self-center d-flex flex-column align-items-center rounded-4 p-4 pt-2' style={{ maxWidth: '500px' }}>
            <h2 className='h2 mb-4'>Recuperation de compte</h2>
            <Formik
                validationSchema={userSchema}
                onSubmit={async (values, { setSubmitting }) => {
                             try {
                                const url =`${baseUrl}/`
                                 axios.get()
                             
                              } catch (error) {
                                 console.log(`an error eccur ${JSON.stringify(error)}`)
                           }
                
                }}
                initialValues={{ email: '', password: '' }}
               >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValidating }) => (
                    <Form noValidate className='p-2' onSubmit={handleSubmit}>
                        <Form.Group md='4' controlId='validationCustomUsername'>
                            <Form.Label>Entrez votre email</Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id='inputGroupPrepend'>@</InputGroup.Text>
                                <Form.Control
                                    type='email'
                                    placeholder='Votre adresse email'
                                    name='email' // Assurez-vous d'ajouter le nom du champ ici
                                    value={values.email} // Liaison de la valeur du champ à l'état local
                                    onChange={handleChange} // Gestion de l'événement onChange
                                    onBlur={handleBlur} // Gestion de l'événement onBlur
                                    isInvalid={touched.email && !!errors.email} // Utilisation des erreurs de validation de Formik
                                    required
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.email}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                     
                  
                        <Button type='submit' className='w-100 bg-success my-2' disabled={isSubmitting}>
                            Valider
                        </Button>
                    </Form>
                )}
            </Formik>
            <Link to={`${appRoutes.changePassWord}`} className=' text-danger pt-0 mt-0'><small>Mot de passe oublié ?</small> </Link>
        </div>
    </div>
    )
}

export default ChangePassWord