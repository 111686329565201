import React from 'react'
import { Link } from 'react-router-dom'
import TableOperationMedical from './Tables/TableOperationMedical'
import { appRoutes} from '../../constant/constant'

const OperationMedical = () => {
    return (
        <div className='p-2 ' >
        <div className='m-2 ' >
            <Link to={`../${appRoutes.register_operations}`}  className='btn bg-success ' >Ajouter</Link>
        </div>
        <TableOperationMedical />
    </div>
    )
}

export default OperationMedical