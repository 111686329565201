import React, { useState } from "react";
import {  NavLink } from "react-router-dom";
import { Appname, appRoutes } from "../../constant/constant";
import logo from '../../img/logo.jpg'
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

const SideBarMedecin = () => {
  const [openPartenair, setopenPartenair] = useState(false) ; 
 const [openPersonnel, setopenPersonnel] = useState(false);


  return ( 
    <div className="sidebar ps-0  ">
      <nav className="navbar navbar-expand">
        <div className="container-fluid d-flex flex-column">
          <div className="navbar-brand text-primary mb-1 pt-0 p-1">
                 <img src={logo} className="img-fluid mx-auto object-fit-contain" alt={`${Appname}`}  />                                           
          </div>
      
          {/* Liens vers d'autres pages */}
          <ul className="navbar-nav d-flex flex-column ">
            <li className="nav-item my-1"> 
                <NavLink to={appRoutes.home} className='nav-link' >
                          <i class="fa fa-home me-2" aria-hidden="true"></i> Acceuil
                </NavLink>
            </li>                         
        
           

            
             <hr></hr>
         
          
             <span></span>
          </ul>
        </div>
      </nav>
    </div>
  );
}
 
export default SideBarMedecin;
