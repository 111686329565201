import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import formatDateTime from '../../../utils/dateFormate'
import { appRoutes } from '../../../constant/constant'
import { GrFormView } from 'react-icons/gr'

const TableTemplatePrescription = ({prescriptions}) => {

    return (
        <Table striped bordered hover>
        <thead>
            <tr>
                <th>#</th>
                <th>Date prescription</th>
                <th>Agent/clinique </th>
                <th>Assuré </th>
                <th>Titre</th>
                <th>Description</th>
                
                <th>Avis M. Conseil</th>
                <th>Actions</th>
        
            </tr>
        </thead>
        <tbody>
        {prescriptions.map((prescription, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{formatDateTime(prescription.datePrescription) } </td>
                            <td>
                            
                               <Link  className='link-secondary link-underline-opacity-0' >
                                         <p>{prescription.agent_clinique?`${prescription.agent_clinique.nom} ${prescription.agent_clinique.prenom}`:null} </p>
                               </Link>
                           </td>
                            <td>
                                <Link 
                                       className='link-secondary link-underline-opacity-0'   >
                                       <p>{ prescription.assure?`${prescription.assure.nom} ${prescription.assure.prenom}`:null}</p>
                                </Link>
                                
                                <small>{prescription.assure?`${prescription.assure.matricule}`:null} </small>
                            </td>
                            <td>{prescription.titre}</td>
                            <td className='text-truncate'>{prescription.description}</td>
                           
                            <td>{prescription.statusMedecinConseille?<p className='text-success' >Approuvé</p> :<p className='text-danger' >Non approuvé</p> }</td>
                            <td>{prescription.statusAgentAssurance?
                                                  <span className='text-success'>Validé</span>:<span className='text-danger'>Non validé</span>}
                                  
                            </td>
                            </tr>
                    ))}    
                       
         </tbody>
       </Table>       
    )
}

export default TableTemplatePrescription