import React from 'react'

const Partenaire = () => {
    return (
        <div>
            Partenaire
        </div>
    )
}

export default Partenaire