import React from 'react'
import { Link } from 'react-router-dom'
import AssureTable from './Tables/TableAssure'
import { appRoutes } from '../../constant/constant'

const Assure = () => {
    
    return (
        <div className='p-3 p ' >
          <div className='mb-2' >
                   <Link to={`../${appRoutes.register_assure}`}  className='btn bg-success text-light ' >Ajouter</Link>
               </div>
               <AssureTable />
        </div>
    )
}

export default Assure