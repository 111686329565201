// ListClinique.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Container, Col } from 'react-bootstrap';
import DynamicTable from '../../../global/components/DynamicTable';
import ActionsColumn from '../ActionsColumn';
import AddClinique from './AddClinique';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';
import TablePartenaire from '../Tables/TablePartenaire';



const Clinique = () => {


  return (
    <div className='p-3 p ' >
    <div className='mb-2' >
             <Link to={`../${appRoutes.register_partenaire}`} state={{type:'clinique'}} className='btn bg-success text-light ' >Ajouter</Link>
         </div>
         <TablePartenaire />
  </div>
  );
};

export default Clinique;
