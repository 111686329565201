import React from 'react';
import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const UserCard = ({ employe }) => {
    return (
        <Card className='mb-3'>
            <Card.Body className='text-center'>
                <div className='mb-3'>
                    <img
                        src={employe.image?employe.image:require('../../assets/user.png')}
                        alt={`${employe.nom} ${employe.prenom}`}
                        className='rounded-circle'
                        width='100'
                        height='100'
                    />
                </div>
                <Card.Title>{`${employe.nom} ${employe.prenom}`}</Card.Title>
                <Card.Text><strong>Email:</strong> {employe.email}</Card.Text>
                <Card.Text><strong>Matricule:</strong> {employe.matricule}</Card.Text>
                <Card.Text><strong>Téléphone:</strong> {employe.tel}</Card.Text>
            </Card.Body>
        </Card>
    );
};

export default UserCard;
