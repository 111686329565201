import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

function AddMedecinConseille({ refreshData }) {
  const [show, setShow] = useState(false);
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [adresse, setAdresse] = useState('');

  const handleClose = () => {
    setShow(false);
    // Réinitialiser les champs du formulaire lorsque le modal est fermé
    setNom('');
    setEmail('');
    setTelephone('');
    setAdresse('');
  };

  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Vous pouvez ajouter ici la logique pour envoyer les données du formulaire à votre backend
    // et rafraîchir les données après avoir ajouté un nouveau médecin conseillé
    refreshData();
    handleClose(); // Fermer le modal après soumission du formulaire
  };

  return (
    <>
      <div className="position-fixed  end-0 p-3">
        <Button variant="success" onClick={handleShow}>
          Ajouter
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-success' >Enregistrer un médecin conseillé</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formNom">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez le nom"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Entrez l'email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTelephone">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Entrez le téléphone"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAdresse">
              <Form.Label>Adresse</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez l'adresse"
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
              />
            </Form.Group>
            {/* Ajoutez d'autres champs ici si nécessaire */}
            <Button variant="success" type="submit">
              Enregistrer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddMedecinConseille;
