import React, { useEffect, useState } from 'react';
import { appRoutes } from '../../../constant/constant';
import { API } from '../../../api/API';
import { useNavigate } from 'react-router-dom';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { GrFormView } from "react-icons/gr";
import { Modal, Table, Button } from 'react-bootstrap';

const TablePolice = () => {
    const [polices, setPolices] = useState([]);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [selectedPolice, setSelectedPolice] = useState(null);

    useEffect(() => {
        getPolices();
    }, []);

    const getPolices = async () => {
        try {
            const res = await API.get(`/polices?limit=${20}`);
            const { data } = res.data;
            setPolices(data);
        } catch (error) {
            console.log(`Error fetching data: ${error}`);
        }
    };

    const handleUpdate = (element) => {
        try {
            navigate(`../${appRoutes.register_police}`, { state: { police: element } });
        } catch (error) {
            console.log(`error ${error}`);
        }
    };

    const handleView = (element) => {
        try {
            navigate(`../${appRoutes.profile_police}`, {state:{element} });
        } catch (error) {
            console.log(`error ${error}`);
        }
    };

    const handleDelete = async (id) => {
        try {
            const resp = await API.delete(`/police/${id}`);
            getPolices();
            handleClose();
        } catch (error) {
            console.log(`some error occurred! ${error}`);
        }
    };

    const confirmDelete = (police) => {
        setSelectedPolice(police);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedPolice(null);
    };

    const handleConfirmDelete = () => {
        if (selectedPolice) {
            handleDelete(selectedPolice.id);
        }
    };

    return (
        <div className='p-1 m-1 accordion-collapse'>
            
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Numero</th>
                        <th>Libelle</th>
                        <th>Description</th>
                        <th>Taux couvrement</th>
                        <th>Cout d'abonnement</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {polices.map((police, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{police.numero}</td>
                            <td>{police.libelle}</td>
                            <td>{police.description}</td>
                            <td>{police.taux_couvrement}%</td>
                            <td>{police.cout_abonnement}</td>
                            <td>
                                <span onClick={() => handleUpdate(police)} className='btn btn-primary m-1 me-1'>
                                    <CiEdit size={35} />
                                </span>
                                <span onClick={() => handleView(police)} className='btn btn-secondary m-1 me-1'>
                                    <GrFormView size={35} />
                                </span>
                                <span onClick={() => confirmDelete(police)} className='btn btn-danger m-1 me-1'>
                                    <MdOutlineDeleteOutline size={30} />
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Êtes-vous sûr de vouloir supprimer cette police ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Supprimer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TablePolice;
