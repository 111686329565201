// ListAdminAssurance.js
import React from 'react';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';
import TableAdminAssurance from '../Tables/TableAdminAssurance';

const ListAdminAssurance = () => {
 

  return (
    <div className='p-3 p ' >
        <div className='mb-2' >
                 <Link to={`../${appRoutes.register_agent}`} state={{type:'Admin'}} className='btn bg-success text-light '>Ajouter</Link>
             </div>
             <TableAdminAssurance />
      </div>
  );
};

export default ListAdminAssurance;
