import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { GrFormView } from "react-icons/gr";
import { API } from '../../../api/API';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';

const TableCategorie = () => {
    const [categories, setCategories] = useState([{},{}]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const res = await API.get('/categories');
            const data = res.data;
            setCategories(data);
        } catch (error) {
            console.log(`Error fetching data: ${error}`);
        }
    }

    const handleUpdate = (categorie) => {
        navigate(appRoutes.register_categorie, { state: categorie });
    }

    const handleView = (categorie) => {
        navigate(appRoutes.profile_categorie, { state: categorie });
    }

    const handleDelete = (categorie) => {
        // Handle delete logic
    }

    return (
        <div>
            <p>Categories</p>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Libelle</th>
                        <th>Description</th>
                        <th>Numero</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((categorie, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{categorie.libelle}</td>
                            <td>{categorie.description}</td>
                            <td>{categorie.numero}</td>
                            <td>
                                <span onClick={() => handleUpdate(categorie)}><CiEdit size={35} /></span>
                                <span onClick={() => handleView(categorie)}><GrFormView size={35} /></span>
                                <span onClick={() => handleDelete(categorie)}><MdOutlineDeleteOutline size={30} /></span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default TableCategorie;
