import React from 'react'
import TablePartenaire from './Tables/TablePartenaire'
import { Link } from 'react-router-dom'
import { appRoutes } from '../../constant/constant'
import TableStructure from './Tables/TableStructure'

const Structure = () => {
    return (
        <div className='p-2 ' >
            <div className='m-2 ' >
               <Link to={`../${appRoutes.register_structure}`}  className='btn bg-success  ' >Ajouter</Link>
            </div>
            <TableStructure />
        </div>
    )
}

export default Structure