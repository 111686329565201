import React, { useState } from "react";
import {  NavLink } from "react-router-dom";
import { Appname, appRoutes } from "../../constant/constant";
import logo from '../../img/logo.jpg'
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

const SideBar = () => {
  const [openPartenair, setopenPartenair] = useState(false) ; 
 const [openPersonnel, setopenPersonnel] = useState(false);


  return ( 
    <div className="sidebar ps-0  ">
      <nav className="navbar navbar-expand">
        <div className="container-fluid d-flex flex-column">
          <div className="navbar-brand text-primary mb-1 pt-0 p-1">
                 <img src={logo} className="img-fluid mx-auto object-fit-contain" alt={`${Appname}`}  />                                           
          </div>
      
          {/* Liens vers d'autres pages */}
          <ul className="navbar-nav d-flex flex-column ">
            <li className="nav-item my-1"> 
                <NavLink to={appRoutes.home} className='nav-link' >
                          <i class="fa fa-home me-2" aria-hidden="true"></i> Acceuil
                </NavLink>
            </li>                         
            <li className="nav-item my-1">
                <NavLink to={appRoutes.adminAssure} className="nav-link" >                    
                        <i class="fa fa-user me-2" aria-hidden="true"></i>Assure
                </NavLink>          
            </li>
          

            <li className="nav-item my-1">
              <Button
                className="nav-link"
                onClick={() => setopenPartenair(!openPartenair)}
                aria-controls="example-collapse-text"
                aria-expanded={openPartenair}
              >
                <i className="fa fa-handshake me-2" aria-hidden="true"></i>Partenaires
              </Button>    
              <div style={{position:'relative' ,left:'30px'}}>
                <Collapse in={openPartenair}>
                  <ul className="nav-bar collapse" style={{listStyle:'none'}}>
                    <li><NavLink to={appRoutes.clinique} className="nav-link submenu-item">Clinique</NavLink></li>
                    <li><NavLink to={appRoutes.adminPharmacy} className="nav-link submenu-item">Pharmacy</NavLink></li>
                    <li><NavLink to={appRoutes.adminLaboratoire} className="nav-link submenu-item">Laboratoire</NavLink></li>

                  </ul>
                </Collapse>
              </div>   
            </li>
             <hr></hr>
            <li className="nav-item my-1">
              <NavLink to={appRoutes.adminPolice} className="nav-link">
                <i class="fa fa-puzzle-piece me-2" aria-hidden="true"></i>  Polices
              </NavLink>
            </li>
           
            <li className="nav-item my-1">
              <NavLink to={appRoutes.maladie} className="nav-link" >
                <i class="fa fa-puzzle-piece me-2" aria-hidden="true"></i>  Maladies
              </NavLink>
            </li>
            <li className="nav-item my-1">
              <NavLink to={appRoutes.operation_medical} className="nav-link text-nowrap overflow-hidden " >
                <i class="fa fa-puzzle-piece me-2" aria-hidden="true"></i>  Operations medicales
              </NavLink>
            </li>
            <li className="nav-item my-1">
              <NavLink to={appRoutes.structure} className="nav-link" >
                <i class="fa fa-puzzle-piece me-2" aria-hidden="true"></i>Structures
              </NavLink>
            </li>
            <hr></hr>
            <li className="nav-item my-1">
              <div >           
                <Button
                  className="nav-link"
                  onClick={() => setopenPersonnel(!openPersonnel)}
                  aria-controls="example-collapse-text"
                  aria-expanded={openPersonnel}
                >
                  <i className="fa fa-users me-2" aria-hidden="true"></i>Personnels
                </Button>   
                <div style={{position:'relative' ,left:'30px'}}>
                  <Collapse in={openPersonnel}>
                    <ul className="nav-bar collapse" style={{listStyle:'none'}}>
                      <li className="p-1 nav-item" style={{padding:'10px'}}>
                        <NavLink to={appRoutes.Agent} className="nav-link submenu-item">Agent</NavLink>
                      </li>
                      <li className="p-1">
                        <NavLink to={appRoutes.adminMedecinConseil} className="nav-link submenu-item">Medecin Conseillé</NavLink>
                      </li>
                      <li className="p-1">
                        <NavLink to={appRoutes.adminAdmin} className="nav-link submenu-item">Administrateur</NavLink>
                      </li>                           
                    </ul>
                  </Collapse> 
                </div>   
              </div>                
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
 
export default SideBar;
