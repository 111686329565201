import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { GrFormView } from "react-icons/gr";
import { API } from '../../../api/API';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';

const TableStructure = () => {
    const [structures, setStructures] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchStructures();
    }, []);

    const fetchStructures = async () => {
        try {
            const res = await API.get('/structures');
            const data = res.data.data;
            setStructures(data);
        } catch (error) {
            console.log(`Error fetching data: ${error}`);
        }
    }

    const handleUpdate = (structure) => {
        navigate(appRoutes.register_structure, { state: structure });
    }

    const handleView = (structure) => {
        // Handle view logic
        try {
            navigate(`../${appRoutes.profile_structure}`, {state:{structure} });
        } catch (error) {
            console.log(`error ${error}`);
        }
    }

    const handleDelete = async (id) => {
        // Handle delete logic
        try {       
            const resp=await API.delete(`/structure/${id} `);
            fetchStructures() ;
           } catch (error) {
            console.log(`some error occure! ${error}  `)
          }
    }

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Intitulé</th>
                        <th>Code</th>
                        <th>Téléphone</th>
                        <th>Adresse</th>
                        <th>Email</th>
                        <th>Type</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {structures.map((structure, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{structure.intitule}</td>
                            <td>{structure.code}</td>
                            <td>{structure.tel}</td>
                            <td>{structure.adresse}</td>
                            <td>{structure.email}</td>
                            <td>{structure.type}</td>
                            <td>
                                <span onClick={() => handleUpdate(structure)} className='btn btn-primary me-1' >
                                    <CiEdit size={35} /></span>
                                <span onClick={() => handleView(structure)} className='btn btn-secondary me-1'>
                                    <GrFormView size={35} /></span>
                                <span onClick={() => handleDelete(structure.id)} className='btn btn-danger me-1'>
                                    <MdOutlineDeleteOutline size={30} /></span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default TableStructure;
