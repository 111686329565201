import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import formatDateTime from '../../../utils/dateFormate';
import { API } from '../../../api/API';
import TableTemplatemaladie from '../TableTemplates/TableTemplateMaladie';
import { numberFormat } from '../../../utils/numberFomate';

const ProfilePolice = () => {
    const location = useLocation();
    const [police, setPolice] = useState(null);
    const [maladies, setmaladies] = useState([]) ;
    const [operations, setoperations] = useState([])

    useEffect(() => {
        if (location.state) {
            setPolice(location.state.element);
        }
       
        if (location.state && location.state.element && location.state.element.id) {
            const id =location.state.element.id ;
            getmaladie_ops(id)
        }
       
    }, [location.state]);

    const getmaladie_ops=(id)=>{
        API.get(`/police/${id}/details`).then((res)=>{
           console.log("police detail 11",JSON.stringify(res.data));
           const data_police =res.data.police ;
           const {maladies,operation_medicals,...police}=data_police ;
           setPolice(police)
           setmaladies(maladies);
           setoperations(operation_medicals)
        }).catch(e=>{
            console.log('error')
        })
      }
      

    if (!police) {
        return <p>Loading...</p>;
    }
 
   
    const {
        numero,
        libelle,
        description,
        taux_couvrement,
        cout_abonnement,
        dureeValidite,
        createdAt,
        updatedAt
    } = police;
 
    return (
        <div className='container-fluid m-2 mt-2 p-0 pt-2 '>
            <Card className='p-3'>
                <Row>
                    <Col md={6} className='border-end'>
                        <h3 className='border-bottom'> Police :<small> {numero}</small></h3>
                        <p><strong>Libellé:</strong> {libelle}</p>
                        <p><strong>Description:</strong> {description}</p>
                        <p><strong>Taux de couverture:</strong> {taux_couvrement}%</p>
                        <p><strong>Coût d'abonnement:</strong> {numberFormat(cout_abonnement) }</p>
                        <p><strong>Durée de validité:</strong> {dureeValidite} jours</p>
                        <p><strong>Créée le:</strong> {formatDateTime(createdAt)}</p>
                        <p><strong>Dernière modification:</strong> {formatDateTime(updatedAt)}</p>
                    </Col>
                    
                </Row>
                <Row className='mt-2 '>
                    <Col className='bg-dark-subtle mx-1   ' >
                       <p>Maladies</p>
                      {maladies?<TableTemplatemaladie maladies={maladies} /> :null}       
                    </Col>
                    <Col className='bg-dark-subtle ' >
                      <p>Operations </p>
                    {maladies?<TableTemplatemaladie maladies={operations} /> :null}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default ProfilePolice;
