import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { object, string, number, array } from 'yup';
import { Formik } from 'formik';
import { API } from '../../../api/API';
import { useNavigate } from 'react-router-dom';
import { InputGroup } from 'react-bootstrap';




const RegisterPoliceAssurance = () => {
  const [responseMessage, setResponseMessage] = useState(null);
  const navigate =useNavigate() ;
  const [suggesNumer, setsuggesNumer] = useState(null);
  const [maladies, setMaladies] = useState([]);
  const[operation,setOperation]=useState([]) ;
  
  useEffect(() => {
  API.get('/police-number').then(res=>{
       const number =res.data.number;
       setsuggesNumer(number) ;
  }).catch((error)=>{
    console.log(error)
  }) ;

  API.get('/maladies').then(res=>{
    setMaladies(res.data.data)

  }).catch((error)=>{
    console.log(error)
  })
  API.get('operation_medicales').then(res=>{
    setOperation(res.data.data)
    
  }).catch((error)=>{
    console.log(error)
  })
  }, [])
  

    const policeAssuranceSchema = object({
        numero: string().required(),
        libelle: string().required(),
        description: string(),
        taux_couvrement: number().required(),
        cout_abonnement: number().required(),
        dureeValidite: number().required(),
        maladies:array().required(),
        operations: array().required(),
    });

   

    return (
       
            <div className='opacity-75 bg-light rounded-4 m-2 p-4'>
                <p className='fs-1 m-1' >Enregistrer un nouveau police</p>
                <Formik
                    validationSchema={policeAssuranceSchema}
                    initialValues={{ numero: '',
                                     libelle: '',
                                      description: '',
                                      taux_couvrement: '',
                                      cout_abonnement: '',
                                      dureeValidite: '',
                                      maladies: [],
                                      operations: [] }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            // Handle successful submission                      
                           const resp= await API.post('/police',values) ;
                           setResponseMessage(resp.data.message);
                            setSubmitting(false);
                        } catch (error) {
                            if (error.response) {
                                // La requête a été faite et le serveur a répondu avec un code de statut
                                // qui n'est pas dans la plage 2xx
                                setResponseMessage(`${JSON.stringify(error.response.status) } ${JSON.stringify(error.response.data.error)}`);
                            } else if (error.request) {
                                console.error('Error request:', error.request);
                            } else {
                                // Quelque chose s'est passé lors de la configuration de la requête qui a déclenché une erreur
                                console.error('Error message:', error.message);
                            }
                            console.error('Error config:', error.config);
                            // Handle error
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ values, errors, touched,resetForm,setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                             {responseMessage? <Row> <span className='bg-success-subtle' >{responseMessage} </span> </Row>:null}
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Numéro</Form.Label>
                                    <InputGroup hasValidation>
                                    <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className='bg-success text-light'
                                        onClick={() => { setFieldValue('numero', suggesNumer); }}
                                      >
                                        Obtenir numero
                                    </InputGroup.Text>
                                    <Form.Control
                                        name="numero"
                                        required
                                        type="text"
                                        value={values.numero}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Numéro"
                                        isInvalid={touched.numero && !!errors.numero}
                                    />
                                   
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Libellé</Form.Label>
                                    <Form.Control
                                        name="libelle"
                                        required
                                        type="text"
                                        value={values.libelle}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Libellé"
                                        isInvalid={touched.libelle && !!errors.libelle}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col}>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        name="description"
                                        type="text"
                                        placeholder="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Taux de couverture (%)</Form.Label>
                                    <Form.Control
                                        name="taux_couvrement"
                                        type="number"
                                        placeholder="Taux de couverture"
                                        value={values.taux_couvrement}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.taux_couvrement && !!errors.taux_couvrement}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Coût d'abonnement</Form.Label>
                                    <Form.Control
                                        name="cout_abonnement"
                                        type="number"
                                        placeholder="Coût d'abonnement"
                                        value={values.cout_abonnement}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.cout_abonnement && !!errors.cout_abonnement}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Durée de validité (jours)</Form.Label>
                                    <Form.Control
                                        name="dureeValidite"
                                        type="number"
                                        placeholder="Durée de validité"
                                        value={values.dureeValidite}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.dureeValidite && !!errors.dureeValidite}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <hr />
                            <Row className="mb-3">
                               <Form.Group as={Col} >
                               <Form.Label className='fs-6 fw-bold'>Cocher les maladie de la police </Form.Label>
                                {maladies.map((m)=>(
                                      <Form.Check
                                         type='checkbox'
                                         name="maladie"
                                         id={m.id}
                                         label={m.label} 
                                         key={m.id}
                                         value={m.id}
                                         checked={values.maladies.includes(m.id.toString())}
                                         onChange={() => {
                                            if (values.maladies.includes(m.id.toString())) {
                                                setFieldValue('maladies', values.maladies.filter(id => id !== m.id.toString()));
                                            } else {
                                                setFieldValue('maladies', [...values.maladies, m.id.toString()]);
                                            }
                                          }}
                                         />
                                   ))}
                                     
                               </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className='fw-bold'>Opérations médicales couvertes</Form.Label>                                  
                                    {operation.map((op)=>(
                                      <Form.Check
                                      type="checkbox"
                                      name="operations"
                                      id={op.id}
                                      label={op.label}
                                      key={op.id}
                                      value={op.id}
                                      checked={values.operations.includes(op.id.toString())}
                                      onChange={() => {
                                          if (values.operations.includes(op.id.toString())) {
                                              setFieldValue('operations', values.operations.filter(id => id !== op.id.toString()));
                                          } else {
                                              setFieldValue('operations', [...values.operations, op.id.toString()]);
                                          }
                                        }}  />
                                        ))}
                              </Form.Group>
                            </Row>
                            <Button type="submit" className='bg-success mt-3 me-4  ' disabled={isSubmitting}>Enregistrer</Button>
                            <Button  className='bg-secondary mt-3 me-4' onClick={()=>resetForm() } >clear</Button>
                            <Button  className='bg-warning mt-3 ' onClick={()=>{ navigate(-1) }} >Quitter</Button>
                        </Form>
                    )}
                </Formik>
            </div>
       
    );
}

export default RegisterPoliceAssurance;
