import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { object, string, number, InferType } from 'yup';
import { Formik } from 'formik';




const RegisterOffre = () => {

    const offreSchema = object({
        code: number().required(),
        intitule: string().required(),
        description: string(),
        cout: number().required(),
        dureeValidite: number().required()
    });

    const saveOffre = async (url, offreData) => {
       
    };

    return (
        <div className='login-page w-100 container-fluid h-100 d-flex align-items-center justify-content-center'>
            <div className='opacity-75 bg-light rounded-4 p-4'>
                <Formik
                    validationSchema={offreSchema}
                    initialValues={{ code: '', intitule: '', description: '', cout: '', dureeValidite: '' }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                           
                            setSubmitting(false);
                        } catch (error) {
                            console.error(error);
                            // Handle error
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control
                                        name="code"
                                        required
                                        type="number"
                                        value={values.code}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Code"
                                        isInvalid={touched.code && !!errors.code}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Intitulé</Form.Label>
                                    <Form.Control
                                        name="intitule"
                                        required
                                        type="text"
                                        value={values.intitule}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Intitulé"
                                        isInvalid={touched.intitule && !!errors.intitule}
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col}>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        name="description"
                                        type="text"
                                        placeholder="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Coût</Form.Label>
                                    <Form.Control
                                        name="cout"
                                        type="number"
                                        placeholder="Coût"
                                        value={values.cout}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.cout && !!errors.cout}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Durée de validité (en jours)</Form.Label>
                                    <Form.Control
                                        name="dureeValidite"
                                        type="number"
                                        placeholder="Durée de validité"
                                        value={values.dureeValidite}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.dureeValidite && !!errors.dureeValidite}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Champ requis</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit" className='bg-danger' disabled={isSubmitting}>Enregistrer</Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default RegisterOffre;
