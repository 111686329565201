const Post = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  
    return response;
  } catch (error) {
    console.log(`response ${JSON.stringify( error) }`)
    throw new Error('Erreur lors de la requête POST');
  }
};

export default Post;
