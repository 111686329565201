import React from 'react'
import TableMaladie from './Tables/TableMaladie'
import { Link } from 'react-router-dom'
import { appRoutes } from '../../constant/constant'

const Maladie = () => {
    
    return (
        <div>
              <div className='p-2 ' >
        <div className='m-2 ' >
            <Link to={`../${appRoutes.register_maladie}`}  className='btn bg-success ' >Ajouter</Link>
        </div>
        <TableMaladie type='laboratoire' />
    </div>
        </div>
    )
}

export default Maladie