import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Row, Table } from 'react-bootstrap';
import { API } from '../../api/API';
import formatDateTime from '../../utils/dateFormate';
import { appRoutes } from '../../constant/constant';
import { Link } from 'react-router-dom';
import { GrFormView } from 'react-icons/gr';
import useUserStore from '../../store/store';

const MedecinDasbord = () => {
    const [prescriptions, setPrescriptions] = useState([]);
    const [filteredPrescriptions, setFilteredPrescriptions] = useState([]);
    const [filterAttribute, setFilterAttribute] = useState('nomAssure');
    const [filterText, setFilterText] = useState('');
    const { user } = useUserStore();
    const [filterLabel, setFilterLabel] = useState('Tout');

    useEffect(() => {
        fetchPrescriptions();
    }, []);

    useEffect(() => {
        applyFilter();
    }, [filterAttribute, filterText, prescriptions]);

    const fetchPrescriptions = async () => {
        try {
            const res = await API.get('/prescriptions');
            const data = res.data;
            setPrescriptions(data.data);
        } catch (error) {
            console.log(`Error fetching data: ${error}`);
        }
    };

    const applyFilter = () => {
        const filtered = prescriptions.filter(prescription => {
            if (!filterText) return true;
            const value = getFilterValue(prescription, filterAttribute);
            return value.toLowerCase().includes(filterText.toLowerCase());
        });
        setFilteredPrescriptions(filtered);
    };

    const getFilterValue = (prescription, attribute) => {
        switch (attribute) {
            case 'nomAssure':
                return prescription.assure ? prescription.assure.nom : '';
            case 'nomAgentClinique':
                return prescription.agent_clinique ? prescription.agent_clinique.nom : '';
            default:
                return '';
        }
    };

    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const handleDropdownSelect = (eventKey) => {
        setFilterAttribute(eventKey);
        switch (eventKey) {
            case 'nomAssure':
                setFilterLabel('Assuré');
                break;
            case 'nomAgentClinique':
                setFilterLabel('Clinique');
                break;
           
            default:
                setFilterLabel('Tout');
        }
    };

    const updateValidPrescriptionByMedecinConseil = (prescription) => {
        if (user && user.id) {
            const { id, statusMedecinConseille, ..._prescription } = prescription;
            const new_prescription = {
                ..._prescription,
                statusMedecinConseille: !statusMedecinConseille,
                idMedecinAssurance: user.id,
                dateApprotionMedecinConseille: new Date()
            };

            API.put(`/prescription/${id}`, new_prescription).then((res) => {
                fetchPrescriptions();
            }).catch((error) => {
                console.log(`${JSON.stringify(error)}`);
            });
        }
    };

    return (
        <div className="container p-2 pt-4">
            <div>
                <Form>
                    <Row>
                        <Form.Group className="mb-3 d-flex">
                            <Dropdown onSelect={handleDropdownSelect} 
                                  className='border-end-0 rounded-end-0 me-0'>
                                <Dropdown.Toggle variant="success" 
                                           className='rounded-end-0 '
                                          id="dropdown-basic">
                                     {filterLabel}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <Dropdown.Item eventKey="tout">Tout</Dropdown.Item>
                                    <Dropdown.Item eventKey="nomAssure">Assuré</Dropdown.Item>
                                    <Dropdown.Item eventKey="nomAgentClinique">Cliniquue</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Form.Control
                                type="text"
                                placeholder="Recherche"
                                value={filterText}
                                className='border-start-0 rounded-start-0 ms-0'
                                onChange={handleFilterChange}
                                required
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </div>
            <div className="card">
                <div className="card-header">
                    <p className='fs-3'>Listes des prescriptions</p>
                </div>
                <div className="card-body">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date prescription</th>
                                <th>Agent/clinique</th>
                                <th>Assuré</th>
                                <th>Titre</th>
                                <th>Description</th>
                                <th>Statut M. Conseillé</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPrescriptions.map((prescription, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{formatDateTime(prescription.datePrescription)}</td>
                                    <td>
                                        <Link to={`../${appRoutes.profile_agent_clinique}`} state={prescription.agent_clinique} className='link-secondary link-underline-opacity-0'>
                                            <p>{prescription.agent_clinique ? `${prescription.agent_clinique.nom} ${prescription.agent_clinique.prenom}` : null}</p>
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={`../${appRoutes.profile_assure}`} state={prescription.assure} className='link-secondary link-underline-opacity-0'>
                                            <p>{prescription.assure ? `${prescription.assure.nom} ${prescription.assure.prenom}` : null}</p>
                                        </Link>
                                        <small>{prescription.assure ? `${prescription.assure.matricule}` : null}</small>
                                    </td>
                                    <td>{prescription.titre}</td>
                                    <td className='text-truncate'>{prescription.description}</td>
                                    <td>
                                        {prescription.statusMedecinConseille ?
                                            <span className='text-success'>Approuvé</span> :
                                            <span className='text-warning'>Non Approuvé</span>}
                                    </td>
                                    <td>
                                        {prescription.statusMedecinConseille ?
                                            <Button onClick={() => updateValidPrescriptionByMedecinConseil(prescription)} className='btn-sm btn-danger'>Annuler l'approbation</Button> :
                                            <span>
                                                <Button onClick={() => updateValidPrescriptionByMedecinConseil(prescription)} className='btn-sm btn-warning'>Approuver</Button>
                                            </span>
                                        }
                                        <Link to={`../${appRoutes.profile_prescription}`} state={prescription} className='btn btn-secondary m-1 ms-1'><GrFormView size={35} /></Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default MedecinDasbord;
