
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import ThemeProvider from 'react-bootstrap/ThemeProvider'

import './App.css'

import useUserStore from './store/store';
import { useEffect, useState } from 'react';
import adminRoutes from './route/adminRoutes';
import defaulRoutes from './route/route';
import medecinRoutes from './route/medecinRoutes';
import agentRoute from './route/agentRoutes';

function App() {
  const {user,updateUser,token}= useUserStore() ;
  const [routes, setroutes] = useState(defaulRoutes);

 
  useEffect(() => {
      if (user) {
          switch (user.type) {
              case 'admin':
                 setroutes(adminRoutes)
                  break;
              case 'medecin':
                 setroutes(medecinRoutes)
                  break
              case 'agent':
                 setroutes(agentRoute)
                  break    
              default:
                setroutes(defaulRoutes) ;
                  break;
          }
      }else{
        setroutes(defaulRoutes) ;
      }
  }, [user]);

  console.log("user",user,token) ;

  const router = createBrowserRouter(routes); 
  return (
    <ThemeProvider
          breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
          minBreakpoint="xxs">
            <div className='App container-fluid p-0  vh-100 vw-100'>
            <RouterProvider router={router} />
           </div>  
    </ThemeProvider>  
  );
}

export default App;

  
