import ProfilePrescription from "../admin/components/profiles/ProfilPrescriptiont";
import CurrentUserProfil from "../components/CurrentUserProfil";
import { appRoutes } from "../constant/constant";
import NotFoundPage from "../global/NotFoundPage";
import MedecinDasbord from "../medecin/components/MedecinDasbord";
import HomeMedecin from "../medecin/pages/HomeMedecin";

const medecinRoutes =[
    {
        path: "/",
        element: <HomeMedecin />,
        children: [
          {
            path:'',
            element:<MedecinDasbord/>
          },
          {
            path:'home',
            element:<MedecinDasbord/>
          },
          {
            path:appRoutes.monProfil,
            element:<CurrentUserProfil/>
          },
          {
            path:appRoutes.profile_prescription,
            element:<ProfilePrescription />
          },
          {
            path:'*',
            element:<NotFoundPage/>
          }
        
        ],
      },
]


export default medecinRoutes ;