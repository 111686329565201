import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';

import { GrFormView } from "react-icons/gr";
import { API } from '../../../api/API';
import { Link, useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../constant/constant';
import { Button } from 'react-bootstrap';
import formatDateTime from '../../../utils/dateFormate';
import useUserStore from '../../../store/store';
import MyAlert from '../../../components/MyAlert';

const TablePrescription = () => {
    
    const [prescriptions, setPrescriptions] = useState([]);
    const [noLogin, setnoLogin] = useState(false);
    const {user}=useUserStore();
    const navigate = useNavigate();

    useEffect(() => {
        fetchprescriptions();
    }, []);

    const fetchprescriptions = async () => {
        try {
            const res = await API.get('/prescriptions');
            const data = res.data;
            
            setPrescriptions(data.data);
        } catch (error) {
            console.log(`Error fetching data: ${error}`);
        }
    }

   

    const handleView = (prescription) => {
        navigate(appRoutes.profile_prescription, { state: prescription });
    }

    
   const UpdateValidPrescription =(prescription)=>{
    if (user && user.id) {
        let {id,statusAgentAssurance}=prescription ;
        const new_prescription ={
           ...prescription,
           statusAgentAssurance:!statusAgentAssurance ,
           idAgentAssurance:user.id,
           datePrescriptionAgentAssurance:new Date()
        }
        console.log(new_prescription)
       API.put(`/prescription/${id}`,new_prescription).then((res)=>{
           fetchprescriptions();
       }).catch((error)=>{
           console.log(`${JSON.stringify(error) }`)
       })
    }else{
        setnoLogin(true)
        setTimeout(()=> setnoLogin(false),6000)
    }
   
   }
    return (
        <div>
            <p className='fs-2 ' >Les  Prescriptions</p>
            {noLogin && <MyAlert message="Vous n'etes pas connecter " variant="alert-danger" /> }
            
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date prescription</th>
                        <th>Agent/clinique </th>
                        <th>Assuré </th>
                        <th>Titre</th>
                        <th>Description</th>
                        
                        <th>Avis M. Conseil</th>
                        <th>Actions</th>
                
                    </tr>
                </thead>
                <tbody>
                    {prescriptions.map((prescription, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{formatDateTime(prescription.datePrescription) } </td>
                            <td>
                            
                               <Link to={`../${appRoutes.profile_agent_clinique}`} state={prescription.agent_clinique} className='link-secondary link-underline-opacity-0' >
                                         <p>{prescription.agent_clinique?`${prescription.agent_clinique.nom} ${prescription.agent_clinique.prenom}`:null} </p>
                               </Link>
                           </td>
                            <td>
                                <Link to={`../${appRoutes.profile_assure}`} state={prescription.assure} 
                                       className='link-secondary link-underline-opacity-0'   >
                                       <p>{ prescription.assure?`${prescription.assure.nom} ${prescription.assure.prenom}`:null}</p>
                                </Link>
                                
                                <small>{prescription.assure?`${prescription.assure.matricule}`:null} </small>
                            </td>
                            <td>{prescription.titre}</td>
                            <td className='text-truncate'>{prescription.description}</td>
                           
                            <td>{prescription.statusMedecinConseille?<p className='text-success' >Approuvé</p> :<p className='text-danger' >Non approuvé</p> }</td>
                            <td>{prescription.statusAgentAssurance?
                                                  <span className='text-success'>Validé</span>:
                                                           <Button className='btn btn-warning' onClick={()=>UpdateValidPrescription(prescription)} >Valider</Button> }
                                   <Link to={`../${appRoutes.profile_prescription}`} state={prescription} className='btn btn-secondary m-1 ms-1'><GrFormView size={35} /></Link>
                            </td>
                           
                      
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default TablePrescription;
