import React from 'react'
import { Link } from 'react-router-dom'
import TablePolice from './Tables/TablePoliceAssurance'
import { appRoutes } from '../../constant/constant'


const Police = () => {
    return (
        <div className='p-3 p ' >
            <div className='mb-2' >
                 <Link to={`../${appRoutes.register_police}`}  className='btn bg-success text-light ' >Ajouter</Link>
             </div>
             <TablePolice/>
      </div>
    )
}

export default Police