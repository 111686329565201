import React from 'react'
import { Link } from 'react-router-dom'
import TableAgent from './Tables/TableAgent'
import { appRoutes } from '../../constant/constant'

const Agent = () => {
    return (
        <div className='p-3 p ' >
        <div className='mb-2' >
                 <Link to={`../${appRoutes.register_agent}`} state={{type:'Agent'}}  className='btn bg-success text-light '>Ajouter</Link>
             </div>
             <TableAgent />
      </div>
    )
}

export default Agent